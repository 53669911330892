const React = require("react");
const { useStaticQuery, graphql } = require("gatsby");

module.exports = function usePublicationYears() {
  const { allPublication } = useStaticQuery(
    graphql`
      query AllPublicationYears {
        allPublication(
          filter: {fields: {pagePath: {ne: null}}}
          sort: {bibcite_year: ASC}
        ) {
          distinct(field: {bibcite_year: SELECT})
        }
      }
    `
  );

  return allPublication.distinct
    .sort((a, b) => +b - +a)
    .map((year) => ({
      id: `${year}`,
      name: `${year}`
    }));
};