import React, { useState } from "react";
import Button from "../Button";
import ResetButton from "../ResetButton";
import SearchAppKeyword from "./SearchAppKeyword";
import SearchAppFilter from "./SearchAppFilter";

const SearchAppForm: React.FC<JQI.SearchAppFormProps> = ({
  query,
  filters = [],
  onSubmit = () => {},
  onReset = () => {},
  onQueryChange = () => {},
  onFilterChange = () => {},
}) => {
  const submitLabel = (typeof query !== "undefined" && filters.length === 0) ? "Search" : "Apply";
  const hasInitialFilterValues = filters.map((filter) => filter.value).filter((value) => !!value).length > 0;
  const [isDirty, setIsDirty] = useState<boolean>(hasInitialFilterValues);
  const [submitted, setSubmitted] = useState<boolean>(hasInitialFilterValues);
  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (isDirty) {
      onSubmit(e);
      setSubmitted(true);
    }
  };
  const handleResetClick = () => {
    onReset();
    setSubmitted(false);
  };
  return (
    <form className="search-app__form" onSubmit={handleFormSubmit}>
      <div className="search-app__form-filters">
        {
          typeof query !== "undefined" &&
          <SearchAppKeyword
            query={query}
            onChange={(value: string) => {
              if (!isDirty) setIsDirty(true);
              onQueryChange(value);
            }}
          />
        }
        {
          filters.map(props => (
            <SearchAppFilter
              {...props}
              key={props.property}
              onChange={(property: string, value: string) => {
                if (!isDirty) setIsDirty(true);
                onFilterChange(property, value);
              }}
            />
          ))
        }
      </div>
      <div className="search-app__form-actions">
        <div className="search-app__form-action">
          <Button variant="red" type="submit">{submitLabel}</Button>
        </div>
        {
          (isDirty && submitted) &&
          <div className="search-app__form-action search-app__form-action--reset">
            <ResetButton onClick={handleResetClick} />
          </div>
        }
      </div>
    </form>
  );
};

export default SearchAppForm;