const React = require("react");
const { useStaticQuery, graphql } = require("gatsby");

module.exports = function useEventTypes() {
  const { allTaxonomyTermEventTypes, allNodeEvent } = useStaticQuery(
    graphql`query AllEventTypes {
      allNodeEvent(
        filter: {fields: {pagePath: {ne: null}}}
      ) {
        distinct(field: {relationships: {field_event_type: {name: SELECT}}})
      }
      allTaxonomyTermEventTypes {
        edges {
          node {
            id: drupal_internal__tid
            name
          }
        }
      }
}`
  );

  // A list of the event types used on the current site
  const localEventTypes = allNodeEvent.distinct;
  // Remove events types that are not in localEventTypes
  const filteredEventTypes = allTaxonomyTermEventTypes.edges.filter((item) => {
    return localEventTypes.indexOf(item.node.name) !== -1
  });

  return filteredEventTypes.map((edge) => ({
    ...edge.node,
    id: `${edge.node.id}`,
  }));
};