interface LinksetSidebar {
  parent: {
    url: string;
    title: string;
  }
  current?: {
    title: string;
    url: string;
    children?: JQI.LinksetData[];
  }[];
}

// Turn a linkset object from a page query into a usable menu
const useLinksetNav = (path: string, linksetData: JQI.LinksetData) => {
  // Build the sidebar based on the linkset data;
  // mimic the legacy sidebar structure
  let linksetSidebar = {} as LinksetSidebar;
  if(linksetData && linksetData.parent) {
    linksetSidebar["parent"] = {url: linksetData.parent.href, title: linksetData.parent.title};
    if(linksetData.pageLevel > 1) {
      linksetSidebar["current"] = linksetData.parent.peers?.map((peer) => {
        return(
          {
            title: peer.title,
            url: peer.href,
            children: peer.href === path ? peer.children : [],
          }
        );
      });
    } else {
      linksetSidebar["current"] = [
        {
          title: linksetData.title,
          url: linksetData.href,
          children: linksetData.children,
        }
      ];
    }
  }
  return linksetSidebar;
}

export default useLinksetNav