import React from "react";
import { Helmet, HelmetProps } from "react-helmet";

const OpenGraphMeta: React.FC<JQI.OpenGraphMetaProps> = ({
  type = "article",
  title,
  url,
  siteName,
  description,
  article,
  profile,
  image,
  audio,
}) => {
  const props = {
    meta: [
      {
        property: "og:type",
        content: type,
      },
      {
        property: "og:title",
        content: title,
      },
      {
        property: "og:url",
        content: url,
      },
      {
        property: "og:site_name",
        content: siteName,
      },
      {
        property: "og:description",
        content: description,
      },
      {
        property: "og:article:published_time",
        content: article?.publishedTime,
      },
      {
        property: "og:article:modified_time",
        content: article?.modifiedTime,
      },
      {
        property: "og:article:expiration_time",
        content: article?.expirationTime,
      },
      {
        property: "og:article:author",
        content: article?.author,
      },
      {
        property: "og:article:section",
        content: article?.section,
      },
      {
        property: "og:article:tag",
        content: article?.tag,
      },
      {
        property: "og:profile:first_name",
        content: profile?.firstName,
      },
      {
        property: "og:profile:last_name",
        content: profile?.lastName,
      },
      {
        property: "og:image",
        content: image?.url,
      },
      {
        property: "og:image:type",
        content: image?.type,
      },
      {
        property: "og:image:height",
        content: image?.height,
      },
      {
        property: "og:image:width",
        content: image?.width,
      },
      {
        property: "og:image:alt",
        content: image?.alt,
      },
      {
        property: "og:audio",
        content: audio?.url,
      },
      {
        property: "og:audio:type",
        content: audio?.type,
      },
    ],
  } as HelmetProps;

  // Remove any meta tags that are undefined and trim whitespace from content
  props.meta = props.meta
    ?.map((meta) => ({
      ...meta,
      content: meta?.content?.trim() || ""
    }))
    .filter(({ content }) => !!content);

  return (
    <Helmet {...props} />
  );
};

export default OpenGraphMeta;