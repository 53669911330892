import React from "react";
import Card from "../components/Card";

const BasicPageCard: React.FC<JQI.BasicPageCardProps> = ({
  title,
  fields,
  body,
  relationships,
}) => {
  const cardProps = {
    url: fields?.pagePath,
    image: {
      data: relationships.hero.relationships.heroImage?.relationships.mediaImage.localFile,
      alt: relationships.hero.relationships.heroImage?.mediaImage.alt || "",
    },
    teaser: body?.summary || "",
    title,
  } as JQI.CardProps;
  return (
    <Card {...cardProps} />
  );
};

export default BasicPageCard;
