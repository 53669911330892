import React from "react";
import "./ButtonSet.scss";

const ButtonSet: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  children,
}) => (
  <div className="button-set">
    <ul className="button-set__list">
      {
        React.Children.map(children, (child, i) => (
          <li className="button-set__list-item" key={`button-set-button-${i}`}>
            {child}
          </li>
        ))
      }
    </ul>
  </div>
);

export default ButtonSet;