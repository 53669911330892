import React from "react";
import { navigate } from "gatsby";
import FancyLink from "../FancyLink";
import classNames from "classnames";
import useHover from "../../hooks/use-hover";
import "./GroupFeedItem.scss";

const GroupFeedItem: React.FC<JQI.GroupFeedItemProps> = ({
  link,
  fields,
  title,
  relationships,
  field_about
}) => {
  const [ hoverRef, hovered ] = useHover();
  const classList = classNames({
    "group-feed-item": true,
    hovered,
  });

  return (
    <article className={classList} onClick={() => { navigate(fields.pagePath) }} ref={hoverRef}>
      <h2 className="group-feed-item__title">{title}</h2>

      <div className="group-feed-item__lead">
        <div className="font-semibold">{relationships?.field_group_lead?.title}</div>
        <div className="italic">{relationships?.field_group_lead?.field_person_title}</div>
      </div>

      <div className="group-feed-item__link">
        {link?.uri || fields?.pagePath
          ? <FancyLink href={fields.pagePath || link?.uri}>Learn More</FancyLink>
          : ""
        }
      </div>

    </article>
  )
};

export default GroupFeedItem;
