import React from "react";
import { GatsbyImage, getImage, ImageDataLike, GatsbyImageProps } from "gatsby-plugin-image";
import classNames from "classnames";
import FancyLink from "../FancyLink";
import "./PersonFeedItem.scss";

const PersonFeedItem: React.FC<JQI.PersonFeedItemProps> = ({
  name,
  personTitle,
  large,
  cta,
  fields,
  relationships,
}) => {
  const imageData = relationships.image?.relationships.mediaImage.localFile as ImageDataLike;
  const imageProps = {
    image: !!imageData ? getImage(imageData) : null,
    alt: relationships.image?.mediaImage.alt || "",
  } as GatsbyImageProps;
  const classList = classNames({
    "person-feed-item": true,
    "person-feed-item--large": large,
  });
  return (
    <article className={classList}>
      {
        !!imageProps.image &&
        <GatsbyImage className="person-feed-item__image" {...imageProps} />
      }
      {
        !imageProps.image &&
        <div className="person-feed-item__image person-feed-item__image--placeholder" role="presentation"></div>
      }
      <div className="person-feed-item__content">
        <h2 className="person-feed-item__name">
          {
            !!fields?.pagePath
              ? <FancyLink large={large} href={fields.pagePath}>{name}</FancyLink>
              : <span>{name}</span>
          }
        </h2>
        <p className="person-feed-item__title">{personTitle}</p>
        {
          (!!large && !!cta?.url && !!cta?.text) &&
          <div className="person-feed-item__cta">
            <FancyLink href={cta.url}>{cta.text}</FancyLink>
          </div>
        }
      </div>
    </article>
  );
};

export default PersonFeedItem;