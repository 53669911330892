import React from "react";
import BlockquoteParagraph from "./BlockquoteParagraph";
import ButtonSetParagraph from "./ButtonSetParagraph";
import LinkSetParagraph from "./LinkSetParagraph";
import TextHTMLParagraph from "./TextHTMLParagraph";
import ViewsEmbedParagraph from "./ViewsEmbedParagraph";
import HeroParagraph from "./HeroParagraph";
import PublicationFeed from "../components/PublicationFeed";
import PublicationFeedItem from "../components/PublicationFeedItem";
import PersonFeedStacked from "../components/PersonFeedStacked";
import PersonFeedItemStacked from "../components/PersonFeedItemStacked";
import { filterRelatedPublications, filterPeople } from "../utilities/local-filters";

const ParagraphWidget: React.FC<JQI.ParagraphWidgetProps> = ({
  label,
  widget,
  groupId,
}) => {
  switch(label) {
    case "Blockquote": {
      const props = { ...widget } as JQI.BlockquoteParagraphProps;
      return (
        <BlockquoteParagraph {...props} />
      );
    }
    case "Button Set": {
      const props = { ...widget } as JQI.ButtonSetParagraphProps;
      return (
        <ButtonSetParagraph {...props} />
      );
    }
    case "Hero": {
      const props = { ...widget } as JQI.HeroParagraphProps;
      return (
        <HeroParagraph {...props} />
      );
    }
    case "Link Set": {
      const props = { ...widget } as JQI.LinkSetParagraphProps;
      return (
        <LinkSetParagraph {...props} />
      );
    }
    case "People List": {
      const props = { ...widget } as JQI.PeopleListParagraphProps;
      const people = filterPeople(props.relationships.people, groupId);
      return(
        <div className="flex flex-col my-4">
          <div className="mt-8">
            <h3 className="article-experts__subhead">{props.header ? props.header : ""}</h3>
              <PersonFeedStacked>
                {
                  people.map((person, i) => (
                    <PersonFeedItemStacked key={`group-member-feed-item-${i}`} {...person} />
                  ))
                }
              </PersonFeedStacked>
          </div>
        </div>
      );
    }
    case "Publications List": {
      const props = { ...widget } as JQI.PublicationsListParagraphProps;
      const publications = filterRelatedPublications(props.publications, groupId);
      return(
        <>
          <h3 className="article-related-research__subhead">{props.header ? props.header : ""}</h3>
          <PublicationFeed>
            {
              publications.map((publication: JQI.PublicationFeedItemProps, i: number) => (
                <PublicationFeedItem key={`publication-${i}`} {...publication} />
              ))
            }
          </PublicationFeed>
        </>
      )
    }
    case "Text/HTML": {
      const props = { ...widget } as JQI.TextHTMLParagraphProps;
      return (
        <TextHTMLParagraph {...props} />
      );
    }
    case "Views Embed": {
      const props = { ...widget, groupId } as JQI.ViewsEmbedParagraphProps;
      return (
        <ViewsEmbedParagraph {...props} />
      );
    }
    default: {
      return null;
    }
  }
};

export default ParagraphWidget;