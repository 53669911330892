import React from "react";
import { Link } from "gatsby";
import classNames from "classnames";
import FancyLink from "../FancyLink";
import useExternalLink from "../../hooks/use-external-link";

const LinkSetLink: React.FC<JQI.LinkSetLinkProps> = ({
  href = "",
  variant,
  children,
}) => {
  if (!!variant) {
    switch(variant) {
      case "fancy-link": {
        return <FancyLink href={href}>{children}</FancyLink>
      }
      default: {
        return <a className={variant} href={href}>{children}</a>;
      }
    };
  } else {
    if (href.indexOf("/") === 0) {
      return (
        <Link to={href}>{children}</Link>
      );
    } else {
      const classList = classNames({
        "link": true,
        "link--external": useExternalLink(href),
      });
      return (
        <a className={classList} href={href}>{children}</a>
      );
    }
  }
};

export default LinkSetLink;