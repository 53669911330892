import React from "react";
import { GatsbyImage, getImage, ImageDataLike, GatsbyImageProps } from "gatsby-plugin-image";
import classNames from "classnames";
import FancyLink from "../FancyLink";
import "./PersonFeedItemStacked.scss";

const PersonFeedItemStacked: React.FC<JQI.PersonFeedItemProps> = ({
  name,
  personTitle,
  large,
  cta,
  fields,
  relationships,
}) => {
  const imageData = relationships.image?.relationships.mediaImage.localFile as ImageDataLike;
  const imageProps = {
    image: !!imageData ? getImage(imageData) : null,
    alt: relationships.image?.mediaImage.alt || "",
  } as GatsbyImageProps;
  const classList = classNames({
    "grid": true,
    "person-feed-item--large": large,
  });
  return (
    <>
      <div className="space-y-4">
        {
          !!imageProps.image &&
          <GatsbyImage {...imageProps} />
        }
        {
          !imageProps.image &&
          <div className="w-full person-feed-item__image person-feed-item__image--placeholder" role="presentation"></div>
        }

        
        <h2 className="person-feed-item__name">
          {
            !!fields?.pagePath
              ? <FancyLink large={large} href={fields.pagePath}>{name}</FancyLink>
              : <span>{name}</span>
          }
        </h2>
      </div>
      <div>
        <p className="person-feed-item__title">{personTitle}</p>
        {
          (!!large && !!cta?.url && !!cta?.text) &&
          <div className="person-feed-item__cta">
            <FancyLink href={cta.url}>{cta.text}</FancyLink>
          </div>
        }
      </div>
    </>
  );
};

export default PersonFeedItemStacked;
