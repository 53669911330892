import React from "react";
import "./PageContentBody.scss";

const PageContentBody: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  children,
}) => (
  <div className="page-content__body">
    {children}
  </div>
);

export default PageContentBody;