import React from "react";
import "./CardGroup.scss";

const CardGroup: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  children,
}) => (
  <div className="card-group">
    <ul className="card-group__list grid gap-5 800:grid-cols-3">
      {
        React.Children.map(children, (child, i) => (
          <li className="card-group__list-item" key={`card-${i}`}>
            {child}
          </li>
        ))
      }
    </ul>
  </div>
);

export default CardGroup;