import { useState, useEffect } from "react";

const isBrowser = typeof window !== "undefined";

const useBreakpoint = (breakpoint: number = 1000): boolean => {
  const [ matches, setMatches ] = useState<boolean>(false);
  if (isBrowser) {
    const mediaQuery = window.matchMedia(`(min-width: ${breakpoint}px)`) as MediaQueryList;
    if (mediaQuery.matches !== matches) {
      setMatches(mediaQuery.matches);
    }
    useEffect(() => {
      const handleChange = () => {
        setMatches(mediaQuery.matches);
      };
      handleChange();
      mediaQuery.addEventListener("change", handleChange);
      return () => {
        mediaQuery.removeEventListener("change", handleChange);
      };
    });
  }
  return matches;
};

export default useBreakpoint;