const React = require("react");
const { useStaticQuery, graphql } = require("gatsby");

module.exports = function useResearchAreas() {
  const { allTaxonomyTermResearchAreas } = useStaticQuery(
    graphql`query AllResearchAreas {
  allTaxonomyTermResearchAreas(sort: {name: ASC}) {
    edges {
      node {
        id: drupal_internal__tid
        name
      }
    }
  }
}`
  );

  return allTaxonomyTermResearchAreas.edges.map((edge) => ({
    ...edge.node,
    id: `${edge.node.id}`,
  }));
};