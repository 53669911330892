const React = require("react");
const { useStaticQuery, graphql } = require("gatsby");

module.exports = function useNewsCategories() {
  const { allTaxonomyTermNewsCategories } = useStaticQuery(
    graphql`query AllNewsCategories {
  allTaxonomyTermNewsCategories(sort: {name: ASC}) {
    edges {
      node {
        id: drupal_internal__tid
        name
      }
    }
  }
}`
  );

  return allTaxonomyTermNewsCategories.edges.map((edge) => ({
    ...edge.node,
    id: `${edge.node.id}`,
  }));
};