import React from "react";
import classNames from "classnames";
import "./GroupFeed.scss";

const GroupFeed: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  children,
  className,
}) => {
  const classList = classNames({
    "group-feed": true,
    [`${className}`]: !!className,
  });
  return (
    <div className={classList}>
      <ul className="group-feed__list">
        {
          React.Children.map(children, (child, i) => (
            <li className="group-feed__list-item" key={`group-feed-item-${i}`}>
              {child}
            </li>
          ))
        }
      </ul>
    </div>
  );
};

export default GroupFeed;