import React from "react";
import "./PageContentSidebar.scss";

const PageContentSidebar: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  children,
}) => (
  <div className="page-content__sidebar">
    {children}
  </div>
);

export default PageContentSidebar;