import React from "react";
import classNames from "classnames";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import FancyLink from "../FancyLink";
import QuoteIcon from "../../icons/quote.svg";
import BlockquoteBorder from "./BlockquoteBorder";
import "./Blockquote.scss";

const Blockquote: React.FC<JQI.BlockquoteProps> = ({
  quote,
  author,
}) => {
  const imageProps = {
    data: !!author?.image?.data ? getImage(author.image.data) : null,
    src: author?.image?.src,
    alt: `Portrait of ${author?.name}`,
  };
  const classList = classNames({
    "blockquote": true,
    "blockquote--has-image": !!imageProps.data || !!imageProps.src,
  });
  return (
    <blockquote className={classList}>
      <BlockquoteBorder />
      <div className="blockquote__icon" aria-hidden="true">
        <QuoteIcon height="37" width="42" />
      </div>
      <div
        className="blockquote__quote"
        dangerouslySetInnerHTML={{ __html: quote }}
      />
      <footer className="blockquote__footer">
        {
          !!imageProps.data &&
          <GatsbyImage className="blockquote__author-image" image={imageProps.data} alt={imageProps.alt} />
        }
        {
          !!imageProps.src &&
          <div className="blockquote__author-image">
            <img src={imageProps.src} alt={imageProps.alt} loading="lazy" />
          </div>
        }
        {
          !!author?.name &&
          <div className="blockquote__author-info">
            <FancyLink className="blockquote__author-name" href={author.url}>
              {author.name}
            </FancyLink>
            {
              !!author.titles &&
              <div className="blockquote__author-titles">{author.titles}</div>
            }
          </div>
        }
      </footer>
      <BlockquoteBorder />
    </blockquote>
  );
};

export default Blockquote;