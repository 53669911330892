import React from "react";
import { PageProps, graphql } from "gatsby";
import { useSiteMetadata } from "gatsby-theme-jqi";
import { getImage, ImageDataLike } from "gatsby-plugin-image";
import EventHero from "../components/EventHero";
import HTMLHead from "../components/HTMLHead";
import PageContentBody from "../components/PageContentBody";
import PageContentMain from "../components/PageContentMain";
import PageContentSidebar from "../components/PageContentSidebar";
import SidebarNav from "../components/SidebarNav";
import PageContentLayout from "../layouts/PageContentLayout";
import TextHTMLParagraph from "../wrappers/TextHTMLParagraph";
import { useNavHierarchies } from "gatsby-jqi-graphql-helpers";
import { getExternalGroups, getPublishedGroups, filterUnrelatedChildren } from "../utilities/local-filters";

const Event: React.FC<PageProps<JQI.EventData, JQI.EventContext>> = ({
  data,
  pageContext,
}) => {
  const { page } = data;
  const { defaultNodesByPath = {}, groupId } = pageContext;
  defaultNodesByPath[page.fields.pagePath] = { title: page.title };
  const { breadcrumb } = useNavHierarchies(page.fields.pagePath, defaultNodesByPath)
  const { eventType, researchAreas, groups, hero } = page.relationships;

  let externalGroups = groups ? getExternalGroups(groups, groupId) : [];
  externalGroups = filterUnrelatedChildren(externalGroups, groupId);
  externalGroups = getPublishedGroups(externalGroups);

  const eventHeroProps = {
    title: page.title,
    speaker: {
      name: page.speakerName,
      institution: page.speakerInstitution,
    },
    eventType: eventType.name,
    researchAreas: researchAreas?.length ? researchAreas.map(({ name }) => name) : null,
    groups: externalGroups,
    startDatetime: page.startDatetime,
    endDatetime: page.endDatetime,
    location: page.location,
    link: page.link,
    breadcrumb,
  } as JQI.EventHeroProps;

  const siteMetadata = useSiteMetadata();
  // Extract imageUrl for use in og:image
  const metaImageData = hero?.relationships.heroImage?.relationships.mediaImage.localFile as ImageDataLike;
  const image = !!metaImageData ? getImage(metaImageData): null;
  const imageUrl = siteMetadata.siteUrl + image?.images.fallback?.src;

  // Extract description for use in meta
  const meta = page.metatag?.filter((tag) => tag.attributes.name === "description").map((tag) => (
    {
      description: tag.attributes.content,
    }
  )).pop();

  return (
    <>
      <HTMLHead
        title={page.title}
        description={page.body?.summary || meta?.description}
        path={page.fields.pagePath}
        openGraph={{
          article: {
            publishedTime: page.created,
            modifiedTime: page.changed,
          },
          image: {
            url: imageUrl || "",
          },
        }}
      />
      <PageContentLayout>
        <PageContentSidebar>
          <SidebarNav parent={{ title: "Events", url: "/events" }} />
        </PageContentSidebar>
        <PageContentMain>
          <PageContentBody>
            <EventHero {...eventHeroProps} />
            {
              !!page.body?.processed &&
              <TextHTMLParagraph className="page-body" text={page.body} />
            }
          </PageContentBody>
        </PageContentMain>
      </PageContentLayout>
    </>
  );
};

export default Event;

export const query = graphql`
  query EventPage($id: String!) {
    page: nodeEvent(id: { eq: $id }) {
      ...Event
      meta: field_meta {
        description
      }
    }
  }
`;