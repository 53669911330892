import classNames from "classnames";
import { navigate } from "gatsby";
import React from "react";
import useHover from "../../hooks/use-hover";
import FancyLink from "../FancyLink";
import "./VisitorCard.scss";

const VisitorCard: React.FC<JQI.PersonFeedItemProps> = ({
  name,
  fields,
  relationships,
}) => {
  const [ hoverRef, hovered ] = useHover();
  const classList = classNames({
    "visitor-card": true,
    hovered,
  });
  return (
    <article className={classList} onClick={() => { navigate(fields.pagePath) }} ref={hoverRef}>
      <div className="visitor-card__name-affiliation">
        <h2 className="visitor-card__name">
          <FancyLink href={fields.pagePath}>{name}</FancyLink>
        </h2>
        { relationships.affiliation &&
          <>
            {relationships.affiliation.map((affiliation) => (
              <div className="visitor-card__affiliation">{affiliation.name}</div>
            ))
          }
          </>
        }       
      </div>
      <div className="visitor-card__visits">
        { relationships.field_visit_date && 
        <>
          <p className="visitor-card__visit-title">Visit Dates</p>
          { relationships.field_visit_date &&
              <>
              {
                relationships.field_visit_date.map((date, i) => (
                  <div key={i}>
                    <div className="visitor-card__visit-dates">
                      {date.startDate}{ date.endDate ?  ` to ${date.endDate}` : ""}
                    </div>
                  </div>
                ))
              }
              </>
              
          }
        </>
        }
      </div>
      </article>
  );
};

export default VisitorCard;