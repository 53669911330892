import React from "react";
import classNames from "classnames";
import "./Button.scss";

const Button: React.FC<JQI.ButtonProps> = ({
  variant,
  className,
  children,
  type = "button",
  onClick = () => {},
}) => {
  const classList = classNames({
    "button": true,
    [`button--${variant}`]: !!variant,
    [`${className}`]: !!className,
  });
  return (
    <button className={classList} onClick={onClick} type={type}>
      <span className="button__label">
        {children}
        <span className="button__icon" aria-hidden="true"></span>
      </span>
    </button>
  );
};

export default Button;