import React from "react";
import classNames from "classnames";
import "./PersonFeed.scss";

const PersonFeedStacked: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  children,
  className
}) => {

  const classList = classNames({
    "person-feed": false,
    "": true,
    [`${className}`]: !!className,
  });
  return (
    <div className={classList}>
      <ul className="grid grid-cols-2 800:grid-cols-3 1000:grid-cols-4 gap-4">
        {
          React.Children.map(children, (child, i) => (
            <li className="" key={`person-feed-item-${i}`}>
              {child}
            </li>
          ))
        }
      </ul>
    </div>
  );
};

export default PersonFeedStacked;
