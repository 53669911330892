import React from "react";

const BlockquoteBorder: React.FC = () => (
  <div className="blockquote__border" aria-hidden="true">
    <div className="blockquote__border-circle"></div>
    <div className="blockquote__border-circle"></div>
    <div className="blockquote__border-circle"></div>
    <div className="blockquote__border-circle"></div>
  </div>
);

export default BlockquoteBorder;