import React from "react";
import { navigate } from "gatsby";
import classNames from "classnames";
import useHover from "../../hooks/use-hover";
import SiteSearchResultLink from "./SiteSearchResultLink";
import "./SiteSearchResult.scss";

const SiteSearchResult: React.FC<JQI.SiteSearchResultProps> = ({
  title,
  url,
  pageType,
  _snippetResult,
}) => {
  const [ hoverRef, hovered ] = useHover();
  const classList = classNames({
    "site-search-result": true,
    hovered,
  });
  return (
    <article className={classList} onClick={() => { navigate(url) }} ref={hoverRef}>
      <h2 className="site-search-result__title">
        <SiteSearchResultLink url={url}>{title}</SiteSearchResultLink>
      </h2>
      {
        !!_snippetResult?.description?.value &&
        <p
          className="site-search-result__description"
          dangerouslySetInnerHTML={{__html: _snippetResult.description.value.replace(" …", "…")}}
        ></p>
      }
      <span className="site-search-result__page-type">{pageType}</span>
    </article>
  )
};

export default SiteSearchResult;
