import React from "react";
import { Link } from "gatsby";
import "./Breadcrumb.scss";

const Breadcrumb: React.FC<JQI.BreadcrumbProps> = ({
  items,
}) => (
  <nav className="breadcrumb" aria-label="Breadcrumb">
    <ol className="breadcrumb__list">
      {
        items?.map((item, i) => (
          <li className="breadcrumb__list-item" key={`breadcrumb-item-${i}`}>
            <Link to={item?.url}>{item?.title}</Link>
          </li>
        ))
      }
    </ol>
  </nav>
);

export default Breadcrumb;
