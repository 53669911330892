import React from "react";
import { Link, navigate } from "gatsby";
import classNames from "classnames";
import "./FancyLink.scss";

const FancyLink: React.FC<JQI.FancyLinkProps> = ({
  href = "",
  large,
  className,
  children,
}) => {
  const classList = classNames({
    "fancy-link": true,
    "fancy-link--large": large,
    [`${className}`]: !!className,
  });
  const fancyLinkChildren = (
    <>
      <span className="fancy-link__label">
        {children}
        <span className="fancy-link__icon" aria-hidden="true"></span>
      </span>
    </>
  );
  if (href.indexOf("/") === 0) {
    return (
      <Link className={classList} to={href}>
        {fancyLinkChildren}
      </Link>
    );
  } else {
    return (
      // Note: This component can link internally or externally. When this component
      // is wrapped at a higher order with Gatsby navigation, it can cause the back
      // button in the browser to behave strangely. So we stop event propagation here
      // to avoid both the <a> nav and the higher level nav firing.
      <a className={classList} href={href} onClick={(e) => { if(e) {e.stopPropagation()} else if(window.event) {window.event.cancelBubble = true} }}>
        {fancyLinkChildren}
      </a>
    )
  }
};

export default FancyLink;