import React from "react";
import { Link } from "gatsby";
import classNames from "classnames";

const ButtonLink: React.FC<JQI.ButtonLinkProps> = ({
  href = "",
  variant,
  className,
  children,
}) => {
  const classList = classNames({
    "button": true,
    [`button--${variant}`]: !!variant,
    [`${className}`]: !!className,
  });
  const buttonChildren = (
    <>
      <span className="button__label">
        {children}
        <span className="button__icon" aria-hidden="true"></span>
      </span>
    </>
  );
  if (href.indexOf("/") === 0) {
    return (
      <Link className={classList} to={href}>
        {buttonChildren}
      </Link>
    );
  } else {
    return (
      <a className={classList} href={href}>
        {buttonChildren}
      </a>
    )
  }
};

export default ButtonLink;