import React from "react";
import PodcastFeature from "../components/PodcastFeature";
import SeriesThumbnail from "../images/relatively-certain-podcast-thumbnail.png";

const PodcastFeatureParagraph: React.FC<JQI.PodcastFeatureParagraphProps> = ({
  episodeTitle,
  relationships,
}) => {
  // Hardcoding series values for now, may become dynamic in the future if needed
  const podcastFeatureProps = {
    episode: {
      title: episodeTitle,
      audio: {
        src: `${process.env.GATSBY_DRUPAL_URL}${relationships.episodeAudio?.relationships.mediaAudioFile.uri.url}`,
      },
    },
    series: {
      title: "Relatively Certain Podcast",
      image: {
        src: SeriesThumbnail,
      },
      description: "<p>Hear the latest news about everything from quantum computers to astrophysics, all straight from scientists at the University of Maryland.</p>",
    },
  } as JQI.PodcastFeatureProps;
  return (
    <PodcastFeature {...podcastFeatureProps} />
  );
};

export default PodcastFeatureParagraph;