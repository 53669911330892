import React from "react";
import LinkSet from "../components/LinkSet";
import LinkSetLink from "../components/LinkSetLink";

const LinkSetParagraph: React.FC<JQI.LinkSetParagraphProps> = ({
  style,
  links,
}) => (
  <LinkSet variant={style}>
    {
      links.map(({ uri, title }, i) => (
        <LinkSetLink key={`link-set-link-${i}`} href={uri}>{title}</LinkSetLink>
      ))
    }
  </LinkSet>
);

export default LinkSetParagraph;