import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "./Hero.scss";

const Hero: React.FC<JQI.HeroProps> = ({
  image,
  caption,
}) => {
  if (!!image) {
    const imageProps = {
      image: !!image.data ? getImage(image.data) : null,
      src: image.src,
      alt: image.alt || "",
    };
    return (
      <div className="hero">
        <figure className="hero__media">
          {
            (!!imageProps.image || !!imageProps.src) &&
            <div className="hero__media-image">
              {
                !!imageProps.image &&
                <GatsbyImage image={imageProps.image} alt={imageProps.alt} />
              }
              {
                !!imageProps.src &&
                <div className="hero__media-image-wrapper">
                  <img src={imageProps.src} alt={imageProps.alt} loading="lazy" />
                </div>
              }
            </div>
          }
          {
            !!caption &&
            <figcaption className="hero__media-caption" dangerouslySetInnerHTML={{ __html: caption }} />
          }
        </figure>
      </div>
    );
  } else {
    return null;
  }
};

export default Hero;