import React from "react";
import Hero from "../components/Hero";

const HeroParagraph: React.FC<JQI.HeroParagraphProps> = ({
  caption,
  relationships,
}) => {
  const heroProps = {
    image: {
      data: relationships.heroImage?.relationships.mediaImage.localFile,
      alt: relationships.heroImage?.mediaImage.alt || "",
    },
    caption: caption?.processed,
  } as JQI.HeroProps;
  return (
    <Hero {...heroProps} />
  );
};

export default HeroParagraph;