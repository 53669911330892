import React from "react";

const SearchAppFilterInput: React.FC<JQI.SearchAppFilterProps> = ({
  options = [],
  property,
  type = "text",
  value,
  label,
  defaultOptionLabel,
  onChange = () => {},
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement|HTMLInputElement>) => {
    onChange(property, e.target.value);
  };
  if (!!options.length) {
    return (
      <select
        className="search-app__form-filter"
        name={property}
        id={property}
        value={value}
        onChange={handleChange}
      >
        <option value="" label={defaultOptionLabel || `All ${label}`}></option>
        {
          options.map(({ id, name }) => (
            <option value={id} key={id}>{name}</option>
          ))
        }
      </select>
    );
  } else {
    return (
      <input
        className="search-app__form-input"
        type={type}
        name={property}
        id={property}
        value={value}
        onChange={handleChange}
      />
    );
  }
};

export default SearchAppFilterInput;