import React from "react";
import { Link, navigate } from "gatsby";
import classNames from "classnames";
import useHover from "../../hooks/use-hover";
import "./EventFeedItem.scss";
import BasicLink from "../BasicLink";

const EventFeedItem: React.FC<JQI.EventFeedItemProps> = ({
  title,
  fields,
  startDate,
  speakerName,
  speakerInstitution,
  relationships,
}) => {
  const [ hoverRef, hovered ] = useHover();
  const classList = classNames({
    "event-feed-item": true,
    hovered,
  });
  return (
    <article className={classList} onClick={() => { navigate(fields.pagePath) }} ref={hoverRef}>
      <div className="event-feed-item__top">
        <div className="event-feed-item__date-time">{startDate}</div>
        <div className="event-feed-item__event-type">{relationships.eventType.name}</div>
      </div>
      <div className="event-feed-item__content">
        <h2 className="event-feed-item__title">
          {
            !!fields?.pagePath
              ? <BasicLink url={fields.pagePath}>{title}</BasicLink>
              : <span>{title}</span>
          }
        </h2>
        <p className="event-feed-item__speaker">
          <span className="event-feed-item__speaker-name">{speakerName}</span>
          {
            !!speakerInstitution &&
            <span className="event-feed-item__speaker-institution">{speakerInstitution}</span>
          }
        </p>
      </div>
    </article>
  );
};

export default EventFeedItem;