import React from "react";
import { Link } from "gatsby";
import MenuItems from "../MenuItems";
import LeftChevronIcon from "../../icons/left-chevron.svg";
import "./SidebarNav.scss";

const SidebarNav: React.FC<JQI.SidebarNavProps> = ({
  parent,
  current,
  menuLinks
}) => {
  return (
    <nav className="sidebar-nav" aria-label="Sidebar">
      <ul>
        <li>
          <Link className="sidebar-nav__parent" to={parent.url}>
            <LeftChevronIcon className="icon icon-left-chevron" aria-hidden="true" height="13" width="8" />
            <span>{parent.title}</span>
          </Link>
          {
            !!current?.length &&
            <MenuItems items={menuLinks} />
          }
        </li>
      </ul>
    </nav>
  );
};

export default SidebarNav;