import React from "react";
import Blockquote from "../components/Blockquote";

const BlockquoteParagraph: React.FC<JQI.BlockquoteParagraphProps> = ({
  quote,
  relationships,
}) => {
  const { author } = relationships;
  const blockquoteProps = {
    quote: quote.processed,
    author: {
      name: author?.name,
      url: author?.fields?.pagePath,
      titles: author?.titles,
      image: {
        data: author?.relationships.image?.relationships.mediaImage.localFile,
        alt: author?.relationships.image?.mediaImage.alt,
      },
    },
  } as JQI.BlockquoteProps;
  return (
    <Blockquote {...blockquoteProps} />
  );
};

export default BlockquoteParagraph;
