import React from "react";
import { GatsbyImage, GatsbyImageProps, getImage } from "gatsby-plugin-image";
import parse, { Element } from "html-react-parser";
import TextHTML from "../components/TextHTML";

const TextHTMLParagraph: React.FC<JQI.TextHTMLParagraphProps> = ({
  className,
  text,
}) => {
const parsedText = parse(text.processed, {
    replace(node) {
      const elementNode = node as Element;
      if (elementNode.type === "tag" && elementNode.name === "img") {
        const src = elementNode.attribs?.src;
        if (src) {
          elementNode.attribs.src = src.replace('/sites/default/files/', `${process.env.GATSBY_DRUPAL_URL}/sites/default/files/`);
        }
      }
    },
  });
  return (
    <TextHTML className={className}>
      {parsedText}
    </TextHTML>
  );
};

export default TextHTMLParagraph;
