import { useState, useEffect } from "react";

const useFormatAuthors = (authors: JQI.Author[] = [], limit?: number): string => {
  const [ formattedAuthors, setFormattedAuthors ] = useState<string>("");
  useEffect(() => {
    const output = authors.slice(0, limit || authors.length)
      .map(({ firstName, middleName, lastName }) => {
        const outputParts = [
          `${firstName.substring(0, 1)}.`,
        ] as string[];
        if (!!middleName) {
          if (middleName.indexOf(".") !== -1 || middleName.length === 1) {
            outputParts.push(`${middleName.substring(0, 1)}.`);
          } else {
            outputParts.push(middleName);
          }
        }
        outputParts.push(lastName);
        return outputParts.join(" ");
      })
      .reduce((acc, cur, i, arr) => {
        if (i === arr.length - 1) {
          if (arr.length < authors.length) {
            return `${acc}, and ${authors.length - arr.length} others`;
          } else {
            if (arr.length > 1) {
              return `${acc}, and ${cur}`;
            } else {
              return cur;
            }
          }
        } else {
          return !!acc ? `${acc}, ${cur}` : cur;
        }
      }, "");
      setFormattedAuthors(output);
  });
  return formattedAuthors;
};

export default useFormatAuthors;