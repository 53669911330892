import React, { useEffect, useRef, useState } from "react";
import { Script } from "gatsby";
import FacebookIcon from "../../icons/facebook.svg";
import TwitterXIcon from "../../icons/x-twitter.svg";
import PrinterIcon from "../../icons/printer.svg";
import PlusIcon from "../../icons/plus.svg";
import "./SocialShare.scss";

const SocialShare: React.FC = () => {
  // reinitialize a2a every time the component loads
  // see: https://rangen.medium.com/dynamically-load-google-scripts-with-react-and-the-useeffect-hook-3700b908e50f
  const shareRef = useRef<HTMLDivElement>(null);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const script = document.createElement("SCRIPT") as HTMLScriptElement;
    script.src = "https://static.addtoany.com/menu/page.js";
    script.async = true;
    script.addEventListener('load', () => setLoaded(true));
    shareRef.current?.appendChild(script);
  }, []);
  useEffect(() => {
    if (!loaded) return
    if (window.a2a) {
      window.a2a.init_all();
    }
  }, [loaded]);
  return (
    <div className="social-share print:hidden" ref={shareRef}>
    { loaded &&
      <div className="a2a_kit a2a_kit_size_32 a2a_default_style social-share__buttons a2a_flex_style" data-a2a-icon-color="#E21833,white">
        <a className="a2a_button_facebook social-share__button">
          <FacebookIcon className="icon icon-facebook" aria-hidden="true" width="14" height="22" />
        </a>
        <a className="a2a_button_x social-share__button">
          <TwitterXIcon className="icon icon-twitter" aria-hidden="true" width="23" height="19" />
        </a>
        <a className="a2a_button_print social-share__button">
          <PrinterIcon className="icon icon-printer" aria-hidden="true" width="20" height="18" />
        </a>
        <a className="a2a_dd social-share__button">
          <PlusIcon className="icon icon-plus" aria-hidden="true" width="16" height="16" />
        </a>
      </div>
    }
    </div>
  )
};

export default SocialShare;
