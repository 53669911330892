const query = `{
  allNodePerson(filter: {fields: {pagePath: {ne: null}}}) {
    edges {
      node {
        id
        name: title
        lastName: field_person_last_name
        personTitle: field_person_title
        fields {
          pagePath
        }
        internal {
          contentDigest
        }
        relationships {
          field_visit_date {
            field_visit_start_date
            field_visit_end_date
          }
          roles: field_person_roles {
            id: drupal_internal__tid
            name: name
          }
          image: field_person_image {
            mediaImage: field_media_image {
              alt
            }
            relationships {
              mediaImage: field_media_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      aspectRatio: 1
                      transformOptions: {cropFocus: CENTER}
                      width: 210
                    )
                  }
                }
              }
            }
          }
        }
        filters: relationships {
          researchAreas: field_research_areas {
            id: drupal_internal__tid
          }
          roles: field_person_roles {
            id: drupal_internal__tid
          }
          sites: field_sites {
            id: drupal_internal__tid
          }
        }
      }
    }
  }
}`;

const transformer = (result) => {
  return result.data.allNodePerson.edges.map(({
    node: {
      id,
      filters,
      relationships,
      ...rest
    },
  }) => {
    const visitStartDates = relationships.field_visit_date ? relationships.field_visit_date.map(v => new Date(v.field_visit_start_date)) : [];
    const latestVisitStartDate = visitStartDates.length > 0 ? new Date(Math.max(...visitStartDates)) : null;

    return {
      objectID: id,
      visitDate: latestVisitStartDate ? latestVisitStartDate.getTime() : null,
      filters: {
        researchAreas: filters.researchAreas.map(({ id }) => id),
        roles: filters.roles ? filters.roles.map(({ id }) => id) : [],
        sites: filters.sites ? filters.sites.map(({ id }) => id) : [],
      },
      relationships,
      ...rest
    };
  });
};

module.exports = function generateConfig(groupId) {
  return {
    indexName: `people_index_${groupId}`,
    settings: {
      searchableAttributes: [
        "name",
      ],
      attributesForFaceting: [
        "filterOnly(filters.researchAreas)",
        "filterOnly(filters.roles)",
        "filterOnly(filters.sites)",
      ],
      hitsPerPage: 40,
    },
    mergeSettings: true,
    query,
    transformer,
  }
};
