import React from "react";
import ArticlesSearchResult from "../../wrappers/ArticlesSearchResult";
import EventsSearchResult from "../../wrappers/EventsSearchResult";
import PeopleSearchResult from "../../wrappers/PeopleSearchResult";
import PublicationsSearchResult from "../../wrappers/PublicationsSearchResult";
import CoursesSearchResult from "../../wrappers/CoursesSearchResult";
import VisitorSearchResult from "../../wrappers/VisitorSearchResult";
import SiteSearchResult from "../SiteSearchResult";

const SearchAppResult: React.FC<JQI.SearchAppResultProps> = ({
  feedType,
  data,
}) => {
  switch (feedType) {
    case "article": {
      return <ArticlesSearchResult {...(data as JQI.ArticlesSearchResultProps)} />;
    }
    case "event": {
      return <EventsSearchResult {...(data as JQI.EventsSearchResultProps)} />;
    }
    case "person": {
      return <PeopleSearchResult {...(data as JQI.PeopleSearchResultProps)} />;
    }
    case "visitor": {
      return <VisitorSearchResult {...(data as JQI.PeopleSearchResultProps)} />;
    }
    case "publication": {
      return <PublicationsSearchResult {...(data as JQI.PublicationsSearchResultProps)} />;
    }
    case "course": {
      return <CoursesSearchResult {...(data as JQI.CoursesSearchResultProps)} />;
    }
    case "all": {
      return <SiteSearchResult {...(data as JQI.SiteSearchResultProps)} />;
    }
    default: {
      return null;
    }
  }
};

export default SearchAppResult;
