import { useStaticQuery, graphql } from "gatsby"

const useFilterOptions = (): JQI.ListViewFilterOptions => {
  const data = useStaticQuery(graphql`
    query {
      sitePlugin(name: {eq: "gatsby-theme-jqi"}) {
        pluginOptions
      }
    }
  `)

  const { listViewFilters } = data.sitePlugin.pluginOptions;
  return !!listViewFilters ? listViewFilters : {};
}

export default useFilterOptions