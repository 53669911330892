const React = require("react");
const { useStaticQuery, graphql } = require("gatsby");

module.exports = function useNavHierarchies(path, defaultNodesByPath) {
  const { allNodePage } = useStaticQuery(
    graphql`
      query PageTitleAndPath {
        allNodePage {
          edges {
            node {
              title
              fields {
                pagePath
              }
            }
          }
        }
      }
    `
  );

  let nodesByPath = {
    "/": { title: "Home" },
    "/404.html": { title: "Page Not Found" },
    "/404": { title: "Page Not Found" },
    "/dev-404-page": { title: "Page Not Found" },
  };

  nodesByPath = {
    ...nodesByPath,
    ...defaultNodesByPath,
  };

  allNodePage.edges.forEach(({ node }) => {
    if (!!node.fields && !!node.fields.pagePath && node.fields.pagePath.charAt(0) === "/" && ! (!!nodesByPath[node.fields.pagePath])) {
      nodesByPath[node.fields.pagePath] = { title: node.title };
    }
  })

  // Create the breadcrumbs
  const pathSegments = path.split("/");
  const breadcrumb = {
    items: pathSegments.map((_segment, i, arr) => {
      const currentPath = arr.slice(0, i + 1).join("/") || "/";
      const currentNode = nodesByPath[currentPath];
      if (!!currentNode) {
        return {
          title: currentNode.title,
          url: currentPath,
        };
      } else {
        console.error("No node found for path:", currentPath);
      }
    }),
  }

  // Create the sidebar
  const pageLevel = path.split("/").length;
  // Filter out articles, persons, groups, etc. from being shown in sidebar nav
  const titlesByPath = Object.entries(nodesByPath)
    .reduce((acc, cur) => {
      const [path, node] = cur;
      return {
        ...acc,
        [path]: node.title,
      };
    }, {});
  const allPaths = Object.keys(titlesByPath);
  const parentPath = path.split("/").slice(0, -1).join("/") || "/";
  const siblingPaths = allPaths
    .filter((p) => {
      // For level 1 pages, only show the current page and its children. For every other level, 
      // show the current page and its children along with siblings of the current page.
      if (pageLevel > 2) {
        return p.split("/").length === pageLevel && p.indexOf(parentPath) === 0 && p !== "/";
      } else {
        return p === path;
      }
    });
  const childPaths = allPaths.filter((p) => p.split("/").length === pageLevel + 1 && p.indexOf(path) === 0);
  const sidebar = {
    parent: {
      title: titlesByPath[parentPath],
      url: parentPath,
    },
    current: siblingPaths.map((p) => ({
      title: titlesByPath[p],
      url: p,
      children: p === path
        ? childPaths
          .map((cp) => ({
            title: titlesByPath[cp],
            url: cp,
          }))
          .sort((a, b) => {
            return a.title.localeCompare(b.title);
          })
        : [],
    })),
  }
  
  return { breadcrumb, sidebar }
};