import React from "react";
import classNames from "classnames";
import "./HomeSection.scss";

const HomeSection: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  children,
  className,
}) => {
  const classList = classNames({
    "home-section": true,
    [`${className}`]: !!className,
  });
  return (
    <div className={classList}>
      <div className="home-section__inner">
        <div className="home-section__content">
          {children}
        </div>
      </div>
    </div>
  );
};

export default HomeSection;