import React from "react";
import classNames from "classnames";
import SearchAppFilterInput from "./SearchAppFilterInput";

const SearchAppFilter: React.FC<JQI.SearchAppFilterProps> = (props) => {
  const { property, label } = props;
  const classList = classNames({
    "search-app__form-field": true,
    [`search-app__form-field--${property.replace(/\./g, "")}`]: true,
  });
  return (
    <div className={classList}>
      <label className="search-app__form-label" htmlFor={property}>{label}</label>
      <SearchAppFilterInput {...props} />
    </div>
  );
};

export default SearchAppFilter;