import React from "react";
import Breadcrumb from "../Breadcrumb";
import "./PageContentHeader.scss";

const PageContentHeader: React.FC<JQI.PageContentHeaderProps> = ({
  title,
  breadcrumb,
  publishDate,
}) => (
  <div className="page-content__header">
    <Breadcrumb {...breadcrumb} />
    <h1 className="page-content__header-title">{title}</h1>
    {
      !!publishDate &&
      <span className="page-content__header-publish-date">{publishDate}</span>
    }
  </div>
);

export default PageContentHeader;