const React = require("react");
const { useStaticQuery, graphql } = require("gatsby");

module.exports = function useChildGroups() {
  const { allTaxonomyTermSites } = useStaticQuery(
    graphql`query AllChildGroups {
  allTaxonomyTermSites(sort: {title: ASC}, filter: {status: {eq: true}}) {
    edges {
      node {
        id
        drupal_internal__tid
        title
        fields {
          pagePath
        }
        parentTerm: parent_term {
          drupal_internal__target_id
        }
        field_about {
          value
          format
        }
        relationships {
          field_group_lead {
            title
            field_person_title
          }
        }
      }
    }
  }
}`
  );

  return allTaxonomyTermSites.edges.map((edge) => edge.node);
};