import { useState, useEffect, useRef } from "react";

const useHover = (): [React.RefObject<HTMLElement>, boolean] => {
  const [ hovered, setHovered ] = useState<boolean>(false);
  const ref = useRef<HTMLElement>(null);
  const handleMouseEnter = () => {
    setHovered(true);
  };
  const handleMouseLeave = () => {
    setHovered(false);
  };
  useEffect(() => {
    const node = ref.current as HTMLElement;
    if (!!node) {
      node.addEventListener("mouseenter", handleMouseEnter);
      node.addEventListener("mouseleave", handleMouseLeave);
      return () => {
        node.removeEventListener("mouseenter", handleMouseEnter);
        node.removeEventListener("mouseleave", handleMouseLeave);
      };
    }
  }, [ref.current]);
  return [ref, hovered];
};

export default useHover;