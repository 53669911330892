import React from "react";
import ArticleFeed from "../ArticleFeed";
import ArticleFeedItem from "../ArticleFeedItem";
import PublicationFeed from "../PublicationFeed";
import PublicationFeedItem from "../PublicationFeedItem";
import "./ArticleRelatedResearch.scss";

const ArticleRelatedResearch: React.FC<JQI.ArticleRelatedResearchProps> = ({
  researchAreas,
  publications,
  relatedArticles,
}) => (
  <section className="article-related-research" aria-labelledby="article-related-research-title">
    <h2 className="article-related-research__title" id="article-related-research-title">About the Research</h2>
    {
      !!researchAreas.length &&
      <div className="article-related-research__research-areas">
        <h3 className="article-related-research__subhead">Research Areas</h3>
        <p>{researchAreas.join(", ")}</p>
      </div>
    }
    {
      !!publications.length &&
      <div className="article-related-research__publications">
        <h3 className="article-related-research__subhead">Reference Publications</h3>
        <PublicationFeed>
          {
            publications.map((publication, i) => (
              <PublicationFeedItem key={`publication-${i}`} {...publication} />
            ))
          }
        </PublicationFeed>
      </div>
    }
    {
      !!relatedArticles.length &&
      <div className="article-related-research__articles">
        <h3 className="article-related-research__subhead">Related Articles</h3>
        <ArticleFeed>
          {
            relatedArticles.map((article, i) => (
              <ArticleFeedItem
                key={`article-${i}`}
                hideDate={true}
                hideDescription={true}
                hideImage={true}
                {...article}
              />
            ))
          }
        </ArticleFeed>
      </div>
    }
  </section>
);

export default ArticleRelatedResearch;