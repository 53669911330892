import React from "react";
import { kebabCase } from "lodash";
import SearchApp from "../SearchApp";
import {
  useNewsCategories,
  useResearchAreas,
  useSites,
  useSemesters,
} from "gatsby-jqi-graphql-helpers";

const isBrowser = typeof window !== "undefined";

const createDictionary = (arr: JQI.TaxonomyTerm[]): { [key: string]: string } => {
  return arr.reduce((acc, cur: JQI.TaxonomyTerm) => ({
    ...acc,
    [kebabCase(cur.name)]: cur.id,
  }), {});
};

const CoursesSearch: React.FC<JQI.ArticlesSearchProps> = ({
  args = [],
  hideSearch,
  groupId,
  queryArgs,
}) => {
  const researchAreaOptions = useResearchAreas() as JQI.TaxonomyTerm[];
  const categoryOptions = useNewsCategories() as JQI.TaxonomyTerm[];
  const siteOptions = useSites() as JQI.TaxonomyTerm[];
  const semesterOptions = useSemesters() as JQI.TaxonomyTerm[];

  const researchAreaFilter = {
    label: "Research Areas",
    property: "filters.researchAreas",
    options: researchAreaOptions,
    value: "",
  } as JQI.SearchAppFilterProps;
  const categoryFilter = {
    label: "News Categories",
    property: "filters.newsCategories",
    options: categoryOptions,
    value: "",
  } as JQI.SearchAppFilterProps;
  const siteFilter = {
    label: "Research Groups",
    property: "filters.sites",
    options: siteOptions,
    value: "",
  } as JQI.SearchAppFilterProps;
  const semesterFilter = {
    label: "Event Seasons",
    property: "filters.semester",
    options: semesterOptions,
    value: "",
  } as JQI.SearchAppFilterProps;
  // If filters were passed via the Drupal View's contextual filters,
  // set the initial filter values accordingly. Otherwise, parse filters
  // from URL hash if any are present.
  if (!!args.length) {
    const researchAreaFilterIds = researchAreaOptions.map(({ id }) => `${id}`);
    const categoryFilterIds = categoryOptions.map(({ id }) => `${id}`);
    const siteFilterIds = siteOptions.map(({ id }) => `${id}`);
    const semesterFilterIds = semesterOptions.map(({ id }) => `${id}`);
    for (let i = 0; i < args.length; i++) {
      const arg = args[i];
      if (researchAreaFilterIds.indexOf(arg) !== -1) {
        researchAreaFilter.value = arg;
      }
      if (categoryFilterIds.indexOf(arg) !== -1) {
        categoryFilter.value = arg;
      }
      if (siteFilterIds.indexOf(arg) !== -1) {
        siteFilter.value = arg;
      }
      if (semesterFilterIds.indexOf(arg) !== -1) {
        semesterFilter.value = arg;
      }
    }
  } else {
    const locationHash = isBrowser ? kebabCase(window.location.hash.slice(1)) : "";
    const researchAreaIdsBySlug = createDictionary(researchAreaOptions);
    const categoryIdsBySlug = createDictionary(categoryOptions);
    const siteIdsBySlug = createDictionary(siteOptions);
    researchAreaFilter.value = researchAreaIdsBySlug[locationHash] || "";
    categoryFilter.value = categoryIdsBySlug[locationHash] || "";
    siteFilter.value = siteIdsBySlug[locationHash] || "";
    semesterFilter.value = semesterIdsBySlug[locationHash] || "";
  }
  const noFilterValues = !researchAreaFilter.value && !semesterFilter.value;

  return (
    <SearchApp
      indexName={`courses_index_${groupId}`}
      feedType="course"
      filters={[
        siteFilter,
        categoryFilter,
      ]}
      queryArgs={queryArgs}
      hideSearch={hideSearch || !!args.length}
      noResultsText={noFilterValues ? `${noResultsInfo}` : undefined}
    />
  );
};

export default CoursesSearch;
