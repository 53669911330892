import React from "react";
import Breadcrumb from "../Breadcrumb";
import "./EventHero.scss";

const EventHero: React.FC<JQI.EventHeroProps> = ({
  title,
  breadcrumb,
  speaker,
  eventType,
  researchAreas,
  groups,
  startDatetime,
  endDatetime,
  location,
  link,
}) => {
  const groupsMarkup = !!groups?.length ? groups.map((group) => {
    return `<a href="${group.link?.uri || group.fields.pagePath}">${group.title}</a>`;
  }).join(", ") : null;
  return (
    <div className="event-hero">
      <Breadcrumb {...breadcrumb} />
      <div className="event-hero__inner">
        <h1 className="event-hero__title">{title}</h1>
        <div className="event-hero__location-date">
          <h2 className="event-hero__location-date-subheading">Date &amp; Time</h2>
          <p className="event-hero__location-date-value">
            <span className="event-hero__start-datetime">{startDatetime}</span>
            {
              (!!endDatetime && endDatetime !== startDatetime) &&
              <>
                <span> to </span>
                <span className="event-hero__end-datetime">{endDatetime}</span>
              </>
            }
          </p>
          {
            (!!location || !!link) &&
            <h2 className="event-hero__location-date-subheading">Where to Attend</h2>
          }
          {
            !!location &&
            <p className="event-hero__location-date-value">{location}</p>
          }
          {
            !!link &&
            <p className="event-hero__location-date-value">
              <a href={link.uri} className="link link--external" target="_blank">{link.title}</a>
            </p>
          }
        </div>
        <div className="event-hero__info">
          {
            !!speaker.name &&
            <>
              <h2 className="event-hero__info-subheading">Speaker</h2>
              <p className="event-hero__info-value">
                <span className="event-hero__speaker-name">{speaker.name}</span>
                {
                  !!speaker.institution &&
                  <span className="event-hero__speaker-institution">{speaker.institution}</span>
                }
              </p>
            </>
          }
          <h2 className="event-hero__info-subheading">Event Type</h2>
          <p className="event-hero__info-value">{eventType}</p>
          {
            !!researchAreas?.length &&
            <>
              <h2 className="event-hero__info-subheading">Research Areas</h2>
              <p className="event-hero__info-value">{researchAreas.join(", ")}</p>
            </>
          }
          {
            (!!groups?.length && !!groupsMarkup) &&
            <>
              <h2 className="event-hero__info-subheading">Related Groups</h2>
              <p className="event-hero__info-value" dangerouslySetInnerHTML={{ __html: groupsMarkup }}></p>
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default EventHero;