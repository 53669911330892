import React from "react";
import { kebabCase } from "lodash";
import SearchApp from "../SearchApp";
import {
  usePersonalRoles,
  useResearchAreas,
  useSites,
} from "gatsby-jqi-graphql-helpers";

const isBrowser = typeof window !== "undefined";

const createDictionary = (arr: JQI.TaxonomyTerm[]): { [key: string]: string } => {
  return arr.reduce((acc, cur: JQI.TaxonomyTerm) => ({
    ...acc,
    [kebabCase(cur.name)]: cur.id,
  }), {});
};

const VisitorSearch: React.FC<JQI.PeopleSearchProps> = ({
  indexName,
  args = [],
  hideSearch,
  groupId,
  queryArgs,
  noResultsInfo
}) => {
  const researchAreaOptions = useResearchAreas() as JQI.TaxonomyTerm[];
  const roleOptions = usePersonalRoles() as JQI.TaxonomyTerm[];
  const siteOptions = useSites() as JQI.TaxonomyTerm[];
  const researchAreaFilter = {
    label: "Research Areas",
    property: "filters.researchAreas",
    options: researchAreaOptions,
    value: "",
  } as JQI.SearchAppFilterProps;
  const roleFilter = {
    label: "Roles",
    property: "filters.roles",
    options: roleOptions.filter((role) => role.id === "20"),
    value: "",
  } as JQI.SearchAppFilterProps;
  const siteFilter = {
    label: "Research Groups",
    property: "filters.sites",
    options: siteOptions,
    value: "",
  } as JQI.SearchAppFilterProps;
  // If filters were passed via the Drupal View's contextual filters,
  // set the initial filter values accordingly. Otherwise, parse filters
  // from URL hash if any are present.
  if (!!args.length) {
    const researchFilterIds = researchAreaOptions.map(({ id }) => `${id}`);
    const roleFilterIds = roleOptions.map(({ id }) => `${id}`);
    const siteFilterIds = siteOptions.map(({ id }) => `${id}`);
    for (let i = 0; i < args.length; i++) {
      const arg = args[i];
      if (researchFilterIds.indexOf(arg) !== -1) {
        researchAreaFilter.value = arg;
      }
      if (roleFilterIds.indexOf(arg) !== -1) {
        roleFilter.value = arg;
      }
      if (siteFilterIds.indexOf(arg) !== -1) {
        siteFilter.value = arg;
      }
    }
  } else {
    const locationHash = isBrowser ? kebabCase(window.location.hash.slice(1)) : "";
    const researchAreaIdsBySlug = createDictionary(researchAreaOptions);
    const roleIdsBySlug = createDictionary(roleOptions);
    const siteIdsBySlug = createDictionary(siteOptions);
    researchAreaFilter.value = researchAreaIdsBySlug[locationHash] || "";
    roleFilter.value = roleIdsBySlug[locationHash] || "";
    siteFilter.value = siteIdsBySlug[locationHash] || "";
  }
  const noFilterValues = !researchAreaFilter.value && !roleFilter.value && !siteFilter.value;

  return (
    <SearchApp
      indexName={`${indexName}${groupId}`}
      feedType="visitor"
      filters={[]}
      queryArgs={queryArgs}
      query=""
      hideSearch={hideSearch}
      noResultsText={noFilterValues ? `${noResultsInfo}` : undefined}
    />
  );
};

export default VisitorSearch;