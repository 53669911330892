import React from "react";
import { Link, navigate } from "gatsby";
import { GatsbyImage, getImage, GatsbyImageProps } from "gatsby-plugin-image";
import classNames from "classnames";
import FancyLink from "../FancyLink";
import useHover from "../../hooks/use-hover";
import "./Card.scss";
import BasicLink from "../BasicLink";

const Card: React.FC<JQI.CardProps> = ({
  title,
  url,
  image,
  teaser,
}) => {
  const [ hoverRef, hovered ] = useHover();
  const imageProps = {
    image: !!image.data ? getImage(image.data) : null,
    src: image.src,
    alt: image.alt || "",
  };
  const classList = classNames({
    "card": true,
    hovered,
  });
  return (
    <article className={classList} onClick={() => {navigate(url)}} ref={hoverRef}>
      {
        !!imageProps.image &&
        <GatsbyImage className="card__image" image={imageProps.image} alt={imageProps.alt} />
      }
      {
        !!imageProps.src &&
        <div className="card__image">
          <img src={imageProps.src} alt={imageProps.alt} loading="lazy" />
        </div>
      }
      <div className="card__content">
        <h2 className="card__title">
          {
            url?.indexOf("/") === 0
              ? <BasicLink url={url}>{title}</BasicLink>
              : <a href={url} className="">{title}</a>
          }
        </h2>
        {
          !!teaser &&
          <p className="card__teaser">{teaser}</p>
        }
        <FancyLink href={url}>
          <>Learn More<span className="sr-only"> about {title}</span></>
        </FancyLink>
      </div>
    </article>
  );
};

export default Card;
