import React from "react";
import { kebabCase } from "lodash";
import SearchApp from "../SearchApp";
import useSiteMetadata from "../../hooks/use-site-metadata";
import useFilterOptions from "../../hooks/use-filter-options";
import {
  usePublicationYears,
  useResearchAreas,
  useSites,
} from "gatsby-jqi-graphql-helpers";

const isBrowser = typeof window !== "undefined";

const createDictionary = (arr: JQI.TaxonomyTerm[]): { [key: string]: string } => {
  return arr.reduce((acc, cur: JQI.TaxonomyTerm) => ({
    ...acc,
    [kebabCase(cur.name)]: cur.id,
  }), {});
};

const PublicationsSearch: React.FC<JQI.PublicationsSearchProps> = ({
  args = [],
  hideSearch,
  groupId,
  queryArgs,
}) => {
  const researchAreaOptions = useResearchAreas() as JQI.TaxonomyTerm[];
  const yearOptions = usePublicationYears() as JQI.TaxonomyTerm[];
  const siteOptions = useSites() as JQI.TaxonomyTerm[];
  const publishedOptions = [
    {
      id: "true",
      name: "Published",
    },
    {
      id: "false",
      name: "Preprint",
    },
  ] as JQI.TaxonomyTerm[];
  const researchAreaFilter = {
    label: "Research Areas",
    property: "filters.researchAreas",
    options: researchAreaOptions,
    value: "",
  } as JQI.SearchAppFilterProps;
  const yearFilter = {
    label: "Publish Years",
    property: "year",
    options: yearOptions,
    value: "",
  } as JQI.SearchAppFilterProps;
  const siteFilter = {
    label: "Research Groups",
    property: "filters.sites",
    options: siteOptions,
    value: "",
  } as JQI.SearchAppFilterProps;
  const publishedFilter = {
    label: "Published Status",
    property: "filters.published",
    options: publishedOptions,
    value: "",
  } as JQI.SearchAppFilterProps;
  // If filters were passed via the Drupal View's contextual filters,
  // set the initial filter values accordingly. Otherwise, parse filters
  // from URL hash if any are present.
  if (!!args.length) {
    const researchAreaFilterIds = researchAreaOptions.map(({ id }) => `${id}`);
    const siteFilterIds = siteOptions.map(({ id }) => `${id}`);
    for (let i = 0; i < args.length; i++) {
      const arg = args[i];
      if (researchAreaFilterIds.indexOf(arg) !== -1) {
        researchAreaFilter.value = arg;
      }
      if (siteFilterIds.indexOf(arg) !== -1) {
        siteFilter.value = arg;
      }
    }
  } else {
    const locationHash = isBrowser ? kebabCase(window.location.hash.slice(1)) : "";
    const researchAreaIdsBySlug = createDictionary(researchAreaOptions);
    const yearsBySlug = createDictionary(yearOptions);
    const siteIdsBySlug = createDictionary(siteOptions);
    researchAreaFilter.value = researchAreaIdsBySlug[locationHash] || "";
    yearFilter.value = yearsBySlug[locationHash] || "";
    siteFilter.value = siteIdsBySlug[locationHash] || "";
  }
  const siteMetadata = useSiteMetadata();
  const filterOptions = useFilterOptions() as JQI.ListViewFilterOptions;
  const defaultFilterArray = siteMetadata.isGroup ? [yearFilter] : [siteFilter, yearFilter];
  const filterArray = !!filterOptions.publications ? filterOptions.publications.map((option: JQI.ListViewFilterPublicationOptions) => {
    if (option === "year") {
      return yearFilter;
    }
    if (option === "site") {
      return siteFilter;
    }
    if (option === "published") {
      return publishedFilter;
    }
  }) as JQI.SearchAppFilterProps[] : defaultFilterArray as JQI.SearchAppFilterProps[];
  return (
    <SearchApp
      indexName={`publications_index_${groupId}`}
      feedType="publication"
      filters={filterArray}
      queryArgs={queryArgs}
      hideSearch={hideSearch}
    />
  );
};

export default PublicationsSearch;