import React from "react";
import "./ResetButton.scss";

const ResetButton: React.FC<React.HTMLAttributes<HTMLButtonElement>> = ({
  onClick,
}) => (
  <button className="reset-button" type="reset" onClick={onClick}>
    <span className="reset-button__label">Reset</span>
  </button>
);

export default ResetButton;