import React from "react";
import { Link } from "gatsby";

const MenuItems: React.FC<JQI.MenuItemsProps> = ({
  items,
}) => {
  if (!!items?.length) {
    return (
      <ul>
        { items.map((item, i) => (
          <li key={`menu-item-${i}-${item.url}`}>
            <Link to={item.url}>{item.title}</Link>
            {
              (!!item.children?.length) &&
              <MenuItems items={item.children} />
            }
          </li>
        )) }
      </ul>
    );
  } else {
    return <></>;
  }
};

export default MenuItems;