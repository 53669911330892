import React from "react";
import { PageProps, graphql } from "gatsby";
import _ from "lodash";
import { useNavHierarchies } from "gatsby-jqi-graphql-helpers";
import { useSiteMetadata } from "gatsby-theme-jqi";
import { getImage, ImageDataLike } from "gatsby-plugin-image";
import GroupFeed from "../components/GroupFeed";
import GroupFeedItem from "../components/GroupFeedItem";
import HTMLHead from "../components/HTMLHead";
import PageContentBody from "../components/PageContentBody";
import PageContentHeader from "../components/PageContentHeader";
import PageContentMain from "../components/PageContentMain";
import PageSection from "../components/PageSection";
import PersonFeed from "../components/PersonFeed";
import PersonFeedItem from "../components/PersonFeedItem";
import PublicationDetails from "../components/PublicationDetails";
import PageContentLayout from "../layouts/PageContentLayout";
import HeroParagraph from "../wrappers/HeroParagraph";
import { getExternalGroups, getPublishedGroups, filterUnrelatedChildren, filterPeople } from "../utilities/local-filters";

const Publication: React.FC<PageProps<JQI.PublicationData, JQI.PublicationContext>> = ({
  data,
  pageContext,
}) => {
  const { page } = data;
  const { defaultNodesByPath = {}, groupId } = pageContext;
  defaultNodesByPath[page.fields.pagePath] = { title: page.title };
  const { breadcrumb } = useNavHierarchies(page.fields.pagePath, defaultNodesByPath)
  const { hero, printDocument, people, groups } = page.relationships;

  let externalGroups = groups ? getExternalGroups(groups, groupId) : [];
  externalGroups = filterUnrelatedChildren(externalGroups, groupId);
  externalGroups = getPublishedGroups(externalGroups);
  const localPeople = people ? filterPeople(people, groupId) : [];

  const siteMetadata = useSiteMetadata();
  // Extract imageUrl for use in og:image
  const metaImageData = hero?.relationships.heroImage?.relationships.mediaImage.localFile as ImageDataLike;
  const image = !!metaImageData ? getImage(metaImageData): null;
  const imageUrl = siteMetadata.siteUrl + image?.images.fallback?.src;

  // Extract description for use in meta
  const meta = page.metatag?.filter((tag) => tag.attributes.name === "description").map((tag) => (
    {
      description: tag.attributes.content,
    }
  )).pop();

  return (
    <>
      <HTMLHead
        title={page.title}
        description={page.abstract?.value || meta?.description}
        path={page.fields.pagePath}
        openGraph={{
          article: {
            publishedTime: page.created,
            modifiedTime: page.changed,
          },
          image: {
            url: imageUrl || "",
          },
        }}
      />
      <PageContentLayout>
        <PageContentMain>
          <PageContentHeader breadcrumb={breadcrumb} title={page.title} />
          <PageContentBody>
            {
              !!hero &&
              <HeroParagraph {...hero} />
            }
            <PageSection className="my-0 py-0">
              {
                !!page.abstract &&
                <>
                  <h2 className="h2 mb-3">Abstract</h2>
                  <div dangerouslySetInnerHTML={{__html: page.abstract.processed}}></div>
                </>
              }
              <h2 className="h2 mt-6 mb-2">Publication Details</h2>
              <PublicationDetails {...page} />
              {
                !!printDocument?.length &&
                <>
                  <h2 className="h2 mt-6 mb-3">Download the Publication</h2>
                  {
                    printDocument.map((document, i) => (
                      <div key={`document-${i}`}><a className="link" href={process.env.GATSBY_DRUPAL_URL + document.relationships.mediaDocument.uri.url} download={true}>{document.name}</a></div>
                    ))
                  }
                </>
              }
            </PageSection>
            {
              (!!localPeople?.length || !!externalGroups?.length) &&
              <PageSection title="Contributors" className="page-section--shadow">
                {
                  !!localPeople?.length &&
                  <>
                    <h3 className="page-section__subhead">People</h3>
                    <PersonFeed>
                      {
                        localPeople.map((person: JQI.PersonFeedItemProps, i: number) => (
                          <PersonFeedItem key={`person-${i}`} {...person} />
                        ))
                      }
                    </PersonFeed>
                  </>
                }
                {
                  !!externalGroups?.length &&
                  <>
                    <h3 className="page-section__subhead">Groups</h3>
                    <GroupFeed>
                      {
                        externalGroups.map((group: JQI.GroupFeedItemProps, i: number) => (
                          <GroupFeedItem key={`group-${i}`} {...group} />
                        ))
                      }
                    </GroupFeed>
                  </>
                }
              </PageSection>
            }
          </PageContentBody>
        </PageContentMain>
      </PageContentLayout>
    </>
  );
};

export default Publication;

export const query = graphql`
  query PublicationQuery($id: String!) {
    page: publication(id: { eq: $id }) {
      ...Publication
      meta: field_meta {
        description
      }
    }
  }
`;
