import React from "react";
import "./PageContentLayout.scss";

const PageContentLayout: React.FC<{children: React.ReactNode}> = ({
  children,
}) => {
  return (
    <div className="page-content">
      {children}
    </div>
  );
};

export default PageContentLayout;