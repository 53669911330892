import React from "react";
import { PageProps, graphql } from "gatsby";
import _ from 'lodash';
import { useNavHierarchies } from "gatsby-jqi-graphql-helpers";
import useSiteMetadata from "../hooks/use-site-metadata";
import { getImage, ImageDataLike } from "gatsby-plugin-image";
import ArticleFeed from "../components/ArticleFeed";
import ArticleFeedItem from "../components/ArticleFeedItem";
import GroupFeed from "../components/GroupFeed";
import GroupFeedItem from "../components/GroupFeedItem";
import HTMLHead from "../components/HTMLHead";
import PublicationFeed from "../components/PublicationFeed";
import PublicationFeedItem from "../components/PublicationFeedItem";
import PageContentBody from "../components/PageContentBody";
import PageContentMain from "../components/PageContentMain";
import PageSection from "../components/PageSection";
import PersonHero from "../components/PersonHero";
import PageContentLayout from "../layouts/PageContentLayout";
import TextHTMLParagraph from "../wrappers/TextHTMLParagraph";
import { getExternalGroups, getPublishedGroups, filterUnrelatedChildren, filterRelatedArticles, filterRelatedPublications } from "../utilities/local-filters";

const Person: React.FC<PageProps<JQI.PersonData, JQI.PersonContext>> = ({
  data,
  pageContext,
}) => {
  const { page, publications } = data;
  const { defaultNodesByPath = {}, groupId } = pageContext;
  defaultNodesByPath[page.fields.pagePath] = { title: page.name };
  const { breadcrumb } = useNavHierarchies(page.fields.pagePath, defaultNodesByPath)
  const { image, contactInfo, cv, researchAreas, groups, roles, articles } = page.relationships;

  let externalGroups = groups ? getExternalGroups(groups, groupId) : [];
  externalGroups = filterUnrelatedChildren(externalGroups, groupId);
  externalGroups = getPublishedGroups(externalGroups);
  const localRelatedArticles = articles ? filterRelatedArticles(articles, groupId) : [];
  const localRelatedPublications = publications.nodes ? filterRelatedPublications(publications.nodes, groupId) : [];

  const personHeroProps = {
    name: page.name,
    roles: roles?.map((role) => role.name),
    personTitle: page.personTitle,
    image: {
      data: image?.relationships.mediaImage.localFile,
      alt: image?.mediaImage.alt,
    },
    contactInfo: contactInfo.map((info) => ({
      email: info.email,
      office: {
        address: info.officeAddress?.processed,
        phone: info.officePhone,
      },
      organization: info.relationships.organization?.name,
      lab: info.lab,
    })),
    cv: !!cv ? `${process.env.GATSBY_DRUPAL_URL}${cv?.relationships.mediaDocument.uri.url}` : "",
    researchProfile: page.researchProfile,
    breadcrumb,
  } as JQI.PersonHeroProps;

  const sortedArticles = _.orderBy(localRelatedArticles, "created", 'desc');
  const sortedPublications = _.orderBy(localRelatedPublications, ['year', 'title'], ['desc', 'asc']);

  const siteMetadata = useSiteMetadata();
  // Extract imageUrl for use in og:image
  const metaImageData = image?.relationships.mediaImage.localFile as ImageDataLike;
  const metaImage =  !!metaImageData ? getImage(metaImageData) : null;
  const imageUrl = siteMetadata.siteUrl + metaImage?.images.fallback?.src;

  return (
    <>
      <HTMLHead
        title={page.name}
        description={page.bio?.summary}
        path={page.fields.pagePath}
        openGraph={{
          type: "profile",
          profile: {
            firstName: page.firstName,
            lastName: page.lastName,
          },
          image: {
            url: imageUrl || "",
          },
        }}
      />
      <PageContentLayout>
        <PageContentMain>
          <PageContentBody>
            <PersonHero {...personHeroProps} />
            <PageSection className="py-0">
              {
                !!page.bio?.processed &&
                <>
                  <h2 className="h2 mb-3">About</h2>
                  <TextHTMLParagraph text={page.bio} />
                </>
              }
              {
                !!researchAreas?.length &&
                <>
                  <h2 className="h2 mt-6 mb-3">Research Areas</h2>
                  <p>{researchAreas.map(({ name }) => name).join(", ")}</p>
                </>
              }
              {
                !!externalGroups?.length &&
                <>
                  <h2 className="h2 mt-6 mb-3">Research Groups</h2>
                  <GroupFeed>
                    {
                      externalGroups.map((group: JQI.GroupFeedItemProps, i: number) => (
                        <GroupFeedItem key={`group-${i}`} {...group} />
                      ))
                    }
                  </GroupFeed>
                </>
              }
              {
                !!sortedPublications.length &&
                <>
                  <h2 className="h2 mt-6 mb-3">Recent Publications</h2>
                  <PublicationFeed limit={3} showMore={true}>
                    {
                      sortedPublications.map((publication, i) => (
                        <PublicationFeedItem
                          key={`publication-${i}`} {...publication} />
                      ))
                    }
                  </PublicationFeed>
                </>
              }
            </PageSection>
            {
              !!articles?.length &&
              <PageSection title="Recent News" className="bg-gray3">
                <ArticleFeed limit={3} showMore={true}>
                  {
                    sortedArticles.map((article, i) => (
                      <ArticleFeedItem key={`article-${i}`} {...article} />
                    ))
                  }
                </ArticleFeed>
              </PageSection>
            }
          </PageContentBody>
        </PageContentMain>
      </PageContentLayout>
    </>
  );
};

export default Person;

export const query = graphql`
  query PersonPage($id: String!, $firstName: String, $lastName: String) {
    page: nodePerson(id: { eq: $id }) {
      meta: field_meta {
        description
      }
      id
      name: title
      created
      changed
      firstName: field_person_first_name
      lastName: field_person_last_name
      personTitle: field_person_title
      fields {
        pagePath
      }
      researchProfile: field_person_research_profile {
        uri
        title
      }
      bio: body {
        processed
        summary
      }
      relationships {
        image: field_person_image {
          mediaImage: field_media_image {
            alt
          }
          relationships {
            mediaImage: field_media_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1
                    transformOptions: {cropFocus: CENTER}
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
        }
        contactInfo: field_person_contact_info {
          email: field_person_ci_email
          officeAddress: field_person_ci_office_address {
            processed
          }
          officePhone: field_person_ci_office_phone
          lab: field_person_ci_lab
          relationships {
            organization: field_person_ci_organization {
              name
            }
          }
        }
        cv: field_person_cv {
          relationships {
            mediaDocument: field_media_document {
              uri {
                url
              }
            }
          }
        }
        researchAreas: field_research_areas {
          name
        }
        groups: field_sites {
          ...GroupFeedItem
        }
        roles: field_person_roles {
          name
        }
        articles: node__article {
          ...ArticleFeedItem
        }
      }
    }
    publications: allPublication(
      filter: {relationships: {author: {elemMatch: {first_name: {eq: $firstName}, last_name: {eq: $lastName}}}}, status: {eq: true}}
    ) {
      nodes {
        ...PublicationFeedItem
      }
    }
  }
`;
