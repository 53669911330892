import React from "react";
import classNames from "classnames";

const HamburgerIcon: React.FC<JQI.SiteHeaderHamburgerIconProps> = ({ expanded }) => {
  const classList = classNames({
    "site-header__hamburger-icon": true,
    expanded,
  });

  return (
    <span className={classList} aria-hidden="true">
      <span className="site-header__hamburger-icon-bar"></span>
      <span className="site-header__hamburger-icon-bar"></span>
      <span className="site-header__hamburger-icon-bar"></span>
      <span className="site-header__hamburger-icon-bar"></span>
    </span>
  );
};

export default HamburgerIcon;