const query = `{
  allNodeArticle(
    filter: {fields: {pagePath: {ne: null}}}
    sort: [{created: DESC}, {title: ASC}]
  ) {
    edges {
      node {
        id
        title
        date: created(formatString: "MMMM D, YYYY")
        fields {
          pagePath
        }
        sites: field_sites {
          drupal_internal__target_id
        }
        body {
          summary
          processed
        }
        internal {
          contentDigest
        }
        relationships {
          hero: field_hero {
            relationships {
              heroImage: field_hero_image {
                mediaImage: field_media_image {
                  alt
                }
                relationships {
                  mediaImage: field_media_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: CONSTRAINED,
                          width: 230
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        }
        filters: relationships {
          newsCategories: field_news_categories {
            id: drupal_internal__tid
          }
          researchAreas: field_research_areas {
            id: drupal_internal__tid
          }
          sites: field_sites {
            id: drupal_internal__tid
          }
        }
      }
    }
  }
}`;

const transformer = (result) => {
  return result.data.allNodeArticle.edges
    .map(({
      node: {
        id,
        date,
        filters,
        ...rest
      },
    }) => ({
      objectID: id,
      timestamp: (new Date(date)).getTime(),
      filters: {
        newsCategories: filters.newsCategories.map(({ id }) => id),
        researchAreas: filters.researchAreas.map(({ id }) => id),
        sites: filters.sites.map(({ id }) => id),
      },
      date,
      ...rest
    }));
};

module.exports = function generateConfig(groupId) {
  return {
    indexName: `articles_index_${groupId}`,
    settings: {
      searchableAttributes: [
        "title",
        "body.summary",
      ],
      attributesForFaceting: [
        "filterOnly(filters.newsCategories)",
        "filterOnly(filters.researchAreas)",
        "filterOnly(filters.sites)",
      ],
      attributesToSnippet: [
        "body.summary:60",
      ],
      hitsPerPage: 30,
      snippetEllipsisText: "…",
    },
    mergeSettings: true,
    query,
    transformer,
  }
};
