import React from "react";
import classNames from "classnames";
import { Link } from "gatsby";
import useExternalLink from "../../hooks/use-external-link";

const SiteSearchResultLink: React.FC<JQI.SiteSearchResultLinkProps> = ({
  children,
  url,
}) => {
  const isExternal = useExternalLink(url);
  const classList = classNames({
    "link": true,
    "link--external": isExternal,
  });
  if (url.indexOf("/") === 0) {
    return (
      <Link className={classList} to={url}>{children}</Link>
    );
  } else {
    return (
      <a className={classList} href={url} target={isExternal ? "_blank" : undefined}>{children}</a>
    );
  }
};

export default SiteSearchResultLink;