const query = `{
  allNodeArticle(filter: {fields: {pagePath: {ne: null}}}) {
    edges {
      node {
        id
        title
        fields {
          pagePath
        }
        body {
          summary
        }
        internal {
          contentDigest
        }
      }
    }
  }
  allNodeEvent(filter: {fields: {pagePath: {ne: null}}}) {
    edges {
      node {
        id
        title
        fields {
          pagePath
        }
        body {
          summary
        }
        internal {
          contentDigest
        }
      }
    }
  }
  allNodePage(filter: {fields: {pagePath: {ne: null}}}) {
    edges {
      node {
        id
        title
        fields {
          pagePath
        }
        body {
          summary
        }
        internal {
          contentDigest
        }
      }
    }
  }
  allNodePerson(filter: {fields: {pagePath: {ne: null}}}) {
    edges {
      node {
        id
        title
        fields {
          pagePath
        }
        body {
          summary
        }
        internal {
          contentDigest
        }
      }
    }
  }
  allPublication(filter: {fields: {pagePath: {ne: null}}}) {
    edges {
      node {
        id
        title
        fields {
          pagePath
        }
        internal {
          contentDigest
        }
      }
    }
  }
  allNodeCourse(filter: {fields: {pagePath: {ne: null}}}) {
    edges {
      node {
        id
        title
        fields {
          pagePath
        }
        internal {
          contentDigest
        }
      }
    }
  }
  allTaxonomyTermSites(filter: {fields: {pagePath: {ne: null}}}) {
    edges {
      node {
        id
        title
        fields {
          pagePath
        }
        body: field_about {
          summary
        }
        internal {
          contentDigest
        }
      }
    }
  }
}`;

const transformNode = (node, pageType) => ({
  objectID: node.id,
  title: node.title,
  url: node.fields.pagePath,
  description: (!!node.body && !!node.body.summary) ? node.body.summary : "",
  pageType,
  internal: node.internal,
});

const transformer = (result) => {
  const transformedArticles = result.data.allNodeArticle.edges.map(({ node }) => transformNode(node, "Article"));
  const transformedEvents = result.data.allNodeEvent.edges.map(({ node }) => transformNode(node, "Event"));
  const transformedGroups = result.data.allTaxonomyTermSites.edges.map(({ node }) => transformNode(node, "Group"));
  const transformedPages = result.data.allNodePage.edges.map(({ node }) => transformNode(node, "Page"));
  const transformedPeople = result.data.allNodePerson.edges.map(({ node }) => transformNode(node, "Person"));
  const transformedCourses = result.data.allNodeCourse.edges.map(({ node }) => transformNode(node, "Course"));
  const transformedPublications = result.data.allPublication.edges.map(({ node }) => transformNode(node, "Publication"));
  return [
    ...transformedArticles,
    ...transformedEvents,
    ...transformedGroups,
    ...transformedPages,
    ...transformedPeople,
    ...transformedPublications,
    ...transformedCourses,
  ];
};

module.exports = function generateConfig(groupId) {
  return {
    indexName: `site_search_index_${groupId}`,
    settings: {
      searchableAttributes: [
        "title",
        "description",
      ],
      attributesToSnippet: [
        "description:60",
      ],
      hitsPerPage: 40,
      snippetEllipsisText: "…",
    },
    mergeSettings: true,
    query,
    transformer,
  }
};
