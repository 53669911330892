import React from "react";

const SearchAppKeyword: React.FC<JQI.SearchAppKeywordProps> = ({
  query,
  onChange,
}) => (
  <div className="search-app__form-field">
    <label className="search-app__form-label sr-only" htmlFor="query">Keyword</label>
    <input
      className="search-app__form-input"
      type="text"
      name="query"
      id="query"
      value={query}
      onChange={(e) => onChange(e.target.value)}
      autoFocus
    />
  </div>
);

export default SearchAppKeyword;