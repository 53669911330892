const query = `{
  allNodeEvent(
    filter: {fields: {pagePath: {ne: null}}}
    sort: {field_event_start_datetime: DESC}
  ) {
    edges {
      node {
        id
        title
        fields {
          pagePath
        }
        date: field_event_start_datetime(formatString: "YYYY-MM-DD")
        field_event_start_datetime
        startDate: field_event_start_datetime(formatString: "MMMM D, YYYY h:mm a")
        speakerName: field_event_speaker_name
        speakerInstitution: field_event_speaker_institution
        internal {
          contentDigest
        }
        relationships {
          eventType: field_event_type {
            name
          }
          quicsEventType: field_quics_event_types {
            name
          }
          hero: field_event_hero {
            relationships {
              heroImage: field_hero_image {
                mediaImage: field_media_image {
                  alt
                }
                relationships {
                  mediaImage: field_media_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: CONSTRAINED,
                          width: 230
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        }
        filters: relationships {
          eventType: field_event_type {
            id: drupal_internal__tid
          }
          quicsEventType: field_quics_event_types {
            id: drupal_internal__tid
          }
          researchAreas: field_research_areas {
            id: drupal_internal__tid
          }
          semester: field_semester {
            id: drupal_internal__tid
          }
        }
      }
    }
  }
}`;

const transformer = (result) => {
  return result.data.allNodeEvent.edges
    .map(({
      node: {
        id,
        date,
        filters,
        ...rest
      },
    }) => ({
      objectID: id,
      timestamp: (new Date(date)).getTime(),
      filters: {
        eventType: !!filters.eventType ? filters.eventType.id : null,
        quicsEventType: !!filters.quicsEventType ? filters.quicsEventType.id : null,
        researchAreas: filters.researchAreas.map(({ id }) => id),
        semester: filters.semester.map(({ id }) => id),
      },
      date,
      ...rest
    }));
};

module.exports = function generateConfig(groupId) {
  return {
    indexName: `events_index_${groupId}`,
    settings: {
      searchableAttributes: [
        "title",
      ],
      attributesForFaceting: [
        "filterOnly(filters.eventType)",
        "filterOnly(filters.quicsEventType)",
        "filterOnly(filters.researchAreas)",
        "filterOnly(filters.semester)",
        "filterOnly(timestamp)",
      ],
      hitsPerPage: 30,
    },
    mergeSettings: true,
    query,
    transformer,
  }
};
