import React from "react";
import classNames from "classnames";
import "./ScrollDownIndicator.scss";

const ScrollDownIndicator: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  className,
}) => {
  const classList = classNames({
    "scroll-down-indicator": true,
    [`${className}`]: !!className,
  });
  return (
    <div className={classList} aria-hidden="true">
      <span className="scroll-down-indicator__label">Scroll Down</span>
    </div>
  );
};

export default ScrollDownIndicator;