const React = require("react");
const { useStaticQuery, graphql } = require("gatsby");

const seasonsMap = {
  SPRING: 0,
  SUMMER: 1,
  FALL: 2,
  WINTER: 3,
};

module.exports = function useResearchAreas() {
  const { allTaxonomyTermSemester } = useStaticQuery(
    graphql`
      query AllSemesters {
        allTaxonomyTermSemester {
          edges {
            node {
              id: drupal_internal__tid
              name
            }
          }
        }
      }
    `
  );

  // Return terms sorted by season and year in descending order
  return allTaxonomyTermSemester.edges
    .map((edge) => ({
    ...edge.node,
    id: `${edge.node.id}`,
  }))
    .sort((a, b) => {
      const aSeason = seasonsMap[a.name.replace(/[^A-z]/g, "").toUpperCase()];
      const bSeason = seasonsMap[b.name.replace(/[^A-z]/g, "").toUpperCase()];
      return bSeason - aSeason;
    })
    .sort((a, b) => {
      const aYear = +a.name.replace(/\D/g, "");
      const bYear = +b.name.replace(/\D/g, "");
      return bYear - aYear;
    });
};