import React, { useState, useRef } from "react";
import { Link } from "gatsby";
import { useSpring, animated } from "react-spring";
import useHeightTransition from "../../hooks/use-height-transition";
import DownChevronIcon from "../../icons/down-chevron.svg";
import "./SectionNav.scss";

const SectionNav: React.FC<JQI.SectionNavProps> = ({
  items,
}) => {

  const [ expanded, setExpanded ] = useState<boolean>(false);
  const listRef = useRef<HTMLUListElement>(null);
  const height = useHeightTransition(expanded, listRef) as number;

  const dropdownStyles = useSpring({
    config: {
      mass: 1,
      tension: 200,
      friction: 25,
      clamp: false,
      precision: 0.01,
      velocity: 0,
      easing: (t) => t,
    },
    from: { height: 0 },
    to: { height: expanded ? height : 0 },
  });
  if (!!items?.length) {
    return (
      <nav className="section-nav" aria-labelledby="section-nav-toggle">
        <button
          type="button"
          className="section-nav__toggle"
          id="section-nav-toggle"
          aria-haspopup="true"
          aria-expanded={expanded}
          onClick={() => { setExpanded(!expanded) }}
        >
          <span>In This Section</span>
          <DownChevronIcon className="icon icon-down-chevron" aria-hidden="true" width="17" height="10" />
        </button>
        <animated.div className="section-nav__dropdown" style={dropdownStyles} aria-hidden={!expanded}>
           <ul ref={listRef}>
            {items.map((children, id) => (
              <li key={id}>
                <Link to={children.url}>{children.title}</Link>
              </li>
            ))}
          </ul>
        </animated.div>
      </nav>
    );
  } else {
    return null;
  }
};

export default SectionNav;
