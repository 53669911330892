const React = require("react");
const { useStaticQuery, graphql } = require("gatsby");

module.exports = function useSites() {
  const { allTaxonomyTermSites, site } = useStaticQuery(
    graphql`query AllSites {
      allTaxonomyTermSites(
        filter: {status: {eq: true}}
        sort: {title: ASC}
      ) {
        edges {
          node {
            id: drupal_internal__tid
            name: title
            parent_term {
              id: drupal_internal__target_id
            }
          }
        }
      }
      site {
        siteMetadata {
          groupId
        }
      }
}`);

  const { groupId } = site.siteMetadata;
  const filteredSites = allTaxonomyTermSites.edges.filter((edge) => {
    const parentIds = edge.node.parent_term?.map((term) => (term.id))
    return (edge.node.id === groupId || parentIds.indexOf(groupId) !== -1);
  })
  
  return filteredSites.map((edge) => edge.node);
};