import { useState, useEffect } from "react";

const useExternalLink = (url: string): boolean => {
  const [ isExternal, setIsExternal ] = useState<boolean>(false);
  useEffect(() => {
    try {
      const urlInstance = new URL(url);
      setIsExternal(urlInstance.host !== window.location.host);
    } catch(_) {
      // pass through if URL was invalid
    }
  });
  return isExternal;
};

export default useExternalLink;