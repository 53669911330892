const query = `{
  allPublication(
    filter: {fields: {pagePath: {ne: null}}}
    sort: [{bibcite_year: DESC}, {title: ASC}, {created: DESC}]
    ) {
    edges {
      node {
        id
        title
        fields {
          pagePath
        }
        date: bibcite_date
        year: bibcite_year
        seriesTitle: bibcite_tertiary_title
        volume: bibcite_volume
        numberOfPages: bibcite_pages
        conferenceName: bibcite_secondary_title
        bookTitle: bibcite_secondary_title
        journal: bibcite_secondary_title
        doi: bibcite_doi
        internal {
          contentDigest
        }
        relationships {
          authors: author {
            firstName: first_name
            middleName: middle_name
            lastName: last_name
          }
          referenceType: bibcite_reference_type {
            label
          }
          printDocument: field_publication_print_document {
            name
            relationships {
              mediaDocument: field_media_document {
                uri {
                  url
                }
              }
            }
          }
        }
        filters: relationships {
          authors: author {
            id: drupal_internal__id
          }
          researchAreas: field_publication_research_area {
            id: drupal_internal__tid
          }
          sites: field_publication_group {
            id: drupal_internal__tid
          }
        }
      }
    }
  }
}`;

const transformer = (result) => {
	return result.data.allPublication.edges.map(
		({ node: { id, date, year, doi, filters, ...rest } }) => {
			// Use date if provided, otherwise use January first of the publication's year, otherwise use January first of current year
			const timestampDateString =
				date || `${year || new Date().getUTCFullYear()}-01-01`;
			return {
				objectID: id,
				timestamp: new Date(timestampDateString).getTime(),
				filters: {
					authors: filters.authors.map(({ id }) => id),
					researchAreas: filters.researchAreas.map(({ id }) => id),
					sites: filters.sites.map(({ id }) => id),
					published: !!doi ? !doi.toLowerCase().includes("arxiv") : false,
				},
				year,
				date,
				...rest,
			};
		},
	);
};

module.exports = function generateConfig(groupId) {
	return {
		indexName: `publications_index_${groupId}`,
		settings: {
			searchableAttributes: ["title"],
			attributesForFaceting: [
				"filterOnly(filters.authors)",
				"filterOnly(filters.researchAreas)",
				"filterOnly(filters.sites)",
				"filterOnly(filters.published)",
				"filterOnly(year)",
			],
			hitsPerPage: 30,
		},
		mergeSettings: true,
		query,
		transformer,
	};
};
