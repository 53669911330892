import React from "react";
import classNames from "classnames";
import useExternalLink from "../../hooks/use-external-link";
import useFormatAuthors from "../../hooks/use-format-authors";
import "./PublicationFeedItem.scss";
import PDFLogo from "../../icons/pdf.svg"

const PublicationFeedItem: React.FC<JQI.PublicationFeedItemProps> = ({
  title,
  fields,
  journal,
  year,
  seriesTitle,
  bookTitle,
  conferenceName,
  volume,
  numberOfPages,
  authorsLimit,
  relationships,
}) => {
  const isBook = relationships.referenceType.label.indexOf("Book") !== -1;
  const isConference = relationships.referenceType.label.indexOf("Conference") !== -1;
  const isJournal = relationships.referenceType.label.indexOf("Journal") !== -1;
  const isExternal = useExternalLink(fields.pagePath);

  const classList = classNames({
    "link": true,
    "publication-link--external": isExternal,    
  });

  const children = [
    <h2 className="publication-feed-item__title" key="title">
      <a href={fields.pagePath}>{title.trim()}</a>
    </h2>,
    <span className="publication-feed-item__authors" key="authors">{useFormatAuthors(relationships.authors, authorsLimit)}</span>,
  ] as React.ReactNode[];

  // Conditionally add optional fields maintaining order they should appear in output
  if (!!journal && isJournal) {
    children.push(<span className="publication-feed-item__journal italic" key="journal">{journal}</span>);
  }
  if (!!seriesTitle) {
    children.push(<span className="publication-feed-item__series-title" key="series-title">{seriesTitle}</span>);
  }
  if (!!bookTitle && isBook) {
    children.push(<span className="publication-feed-item__book-title" key="book-title">{bookTitle}</span>);
  }
  if (!!conferenceName && isConference) {
    children.push(<span className="publication-feed-item__conference-name" key="conference-name">{conferenceName}</span>);
  }
  if (!!volume) {
    children.push(<span className="publication-feed-item__volume font-semibold" key="volume">{volume}</span>);
  }
  if (!!numberOfPages) {
    children.push(<span className="publication-feed-item__pagination" key="pagination">{numberOfPages}</span>);
  }
  // Add publication year at the end
  children.push(<span className="publication-feed-item__year" key="year">({year})</span>);

  // Add external link indicator
  if (fields.pagePath.indexOf("/") === 0) {
    return (
      <article className="publication-feed-item">
        {children.reduce((prev, cur) => [prev, ", ", cur])}
        {
          !!relationships.printDocument?.length &&
          <>
            {
              relationships.printDocument.map((document, i) => (
                <span className="publication-feed-item__print-document" key={`document-${i}`}><a className="link" href={process.env.GATSBY_DRUPAL_URL + document.relationships.mediaDocument.uri.url} download={true}><PDFLogo className="icon icon-pdf" />{document.name}</a></span>
              ))
            }
          </>
        }
      </article>
    );
  } else {
    return (
      <article className="publication-feed-item publication-link--external">
        {children.reduce((prev, cur) => [prev, ", ", cur])}
        {
          !!relationships.printDocument?.length &&
          <>
            {
              relationships.printDocument.map((document, i) => (
                <span className="publication-feed-item__print-document" key={`document-${i}`}><a className="link" href={process.env.GATSBY_DRUPAL_URL + document.relationships.mediaDocument.uri.url} download={true}><PDFLogo className="icon icon-pdf" />{document.name}</a></span>
              ))
            }
          </>
        }
      </article>
    );
  }
  

};

export default PublicationFeedItem;