import React from "react";
import classNames from "classnames";
import "./PageSection.scss";

const PageSection: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  children,
  title,
  className,
}) => {
  const classList = classNames({
    "page-section": true,
    [`${className}`]: !!className,
  });
  return (
    <div className={classList}>
      <div className="page-section__inner">
        {
          !!title &&
          <h2 className="page-section__title">{title}</h2>
        }
        {children}
      </div>
    </div>
  );
};

export default PageSection;