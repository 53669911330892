import React from "react";
import { GatsbyImage, getImage, } from "gatsby-plugin-image";
import "./PodcastFeature.scss";

const PodcastFeature: React.FC<JQI.PodcastFeatureProps> = ({
  episode,
  series,
}) => {
  const seriesImageProps = {
    data: !!series?.image?.data ? getImage(series.image.data) : null,
    src: series?.image?.src,
    alt: series?.image?.alt || `${series.title} thumbnail`,
  };
  return (
    <div className="podcast-feature">
      <div className="podcast-feature__episode">
        <h2 className="podcast-feature__episode-title">Listen to {episode.title}</h2>
        <audio controls src={episode.audio.src} className="podcast-feature__episode-audio"></audio>
      </div>
      <div className="podcast-feature__series">
        <div className="podcast-feature__series-header">
          {
            !!seriesImageProps.data &&
              <GatsbyImage className="podcast-feature__series-image" image={seriesImageProps.data} alt={seriesImageProps.alt} />
          }
          {
            !!seriesImageProps.src &&
              <div className="podcast-feature__series-image">
                <img src={seriesImageProps.src} alt={seriesImageProps.alt} />
              </div>
          }
          <h3 className="podcast-feature__series-title">{series.title}</h3>
        </div>
        {
          !!series.description &&
            <div className="podcast-feature__series-content">
              <div className="podcast-feature__series-description" dangerouslySetInnerHTML={{ __html: series.description }} />
            </div>
        }
        <a href="https://podcasts.apple.com/us/podcast/relatively-certain/id611418918" target="_blank" className="podcast-feature__series-link">See all our episodes on Apple Podcasts</a>
      </div>
    </div>
  );
};

export default PodcastFeature;