import React, { useState, } from "react";
import { Link } from "gatsby";
import useBreakpoint from "../../hooks/use-breakpoint";
import Dropdown from "./Dropdown";

const MainNav: React.FC<JQI.SiteHeaderMainNavProps> = ({
  hidden,
  items,
}) => {
  const isDesktop = useBreakpoint(1000) as boolean;
  const [ activeDropdownIndex, setActiveDropdownIndex ] = useState<number>(-1);
  return (
    <nav
      className="site-header__nav"
      aria-label="Main"
      aria-hidden={!isDesktop && hidden}
    >
      <ul>
        { items.map((item, i) => {
          const id = item.id || `site-header-menu-parent-${i}` as string;
          return (
            <li key={id}>
              <Link to={item.url}>{item.title}</Link>
              {
                (!!item.children?.length) &&
                <Dropdown
                  expanded={i === activeDropdownIndex}
                  items={item.children}
                  parentId={id}
                  parentTitle={item.title}
                  onToggleClick={() => { setActiveDropdownIndex(i === activeDropdownIndex ? -1 : i) }}
                />
              }
            </li>
          );
        }) }
      </ul>
    </nav>
  );
};

export default MainNav;