import React from "react";
import CourseFeedItem from "../components/CourseFeedItem";

const CoursesSearchResult: React.FC<JQI.CoursesSearchResultProps> = ({
  _snippetResult,
  ...rest
}) => {
  const feedItemProps = {
    ...rest,
    body: {
      summary: _snippetResult?.body?.summary?.value?.replace(" …", "…") || "",
      processed: rest.body?.processed,
    },
  } as JQI.CourseFeedItemProps;
  return (
    <CourseFeedItem {...feedItemProps} />
  )
};

export default CoursesSearchResult;
