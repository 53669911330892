import { useRef, useState, useLayoutEffect } from "react";

const isBrowser = typeof window !== "undefined";

const useHeightTransition = (expanded: boolean = false, ref: React.RefObject<HTMLElement>): number => {
  const [ height, setHeight ] = useState<number>(0);
  const heightRef = useRef<number>(height);
  const [ observer ] = useState(() => {
    if (isBrowser) {
      return new ResizeObserver(() => {
        if (!!ref.current && heightRef.current !== ref.current.offsetHeight) {
          heightRef.current = ref.current.offsetHeight;
          setHeight(ref.current.offsetHeight);
        }
      });
    } else {
      return setHeight(0);
    }
  });

  useLayoutEffect(() => {
    if (!!observer) {
      if (expanded && !!ref.current) {
        setHeight(ref.current.offsetHeight);
        observer.observe(ref.current, {});
      }
      return () => observer.disconnect();
    }
  }, [expanded, ref.current]);

  return height;
};

export default useHeightTransition;