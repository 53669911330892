import React from "react";
import "./LinkSet.scss";

const LinkSet: React.FC<JQI.LinkSetProps> = ({
  children,
  variant,
}) => (
  <div className="link-set">
    <ul className="link-set__list">
      {
        React.Children
          .map(children, (child) => React.isValidElement(child) ? React.cloneElement(child, { variant }) : child)
          ?.map((child, i) => (
            <li className="link-set__list-item" key={`link-set-link-${i}`}>{child}</li>
          ))
      }
    </ul>
  </div>
);

export default LinkSet;