import React from "react";
import Pagination from "../Pagination";
import SearchAppResult from "./SearchAppResult";

const SearchAppResults: React.FC<JQI.SearchAppResultsProps> = ({
  currentPage,
  response,
  feedType,
  fetching,
  errorFetching,
  noResultsText = "No results matched your selections. Try adjusting your filters or resetting your search.",
  onPageChange = () => {},
}) => {
  const doneFetching = !fetching && !errorFetching && !!response;
  const doneFetchingWithError = !fetching && errorFetching;
  const results = (response?.hits || []) as JQI.SearchAppResultPropsUnion[];
  return (
    <div className="search-app__results">
      {
        !!doneFetching &&
        <>
          <div className="search-app__results-summary">
            <p aria-live="polite">{`Showing ${response.nbHits} results`}</p>
          </div>
          {
            !!response.nbHits &&
            <>
              <ul className="search-app__results-list">
                {
                  results.map((hit: JQI.SearchAppResultPropsUnion, i) => {
                    return (
                      <li className="search-app__results-list-item" key={`search-app-result-${i}`}>
                        <SearchAppResult feedType={feedType} data={hit} />
                      </li>
                    );
                  })
                }
              </ul>
              <Pagination
                currentPage={currentPage}
                totalPages={response?.nbPages}
                onIndexChange={(activeIndex: number) => { onPageChange(activeIndex) }}
              />
            </>
          }
          {
            !response.nbHits &&
            <p className="search-app__no-results mt-5">{noResultsText}</p>
          }
        </>
      }
      {
        !!doneFetchingWithError &&
        <p className="search-app__error mt-5">An error occurred, please retry your search.</p>
      }
    </div>
  );
};

export default SearchAppResults;