import React from "react";
import classNames from "classnames";
import PaginationButton from "./PaginationButton";
import ChevronSingleIcon from "../../icons/pagination-chevron-single.svg";
import ChevronDoubleIcon from "../../icons/pagination-chevron-double.svg";
import "./Pagination.scss";

const Pagination: React.FC<JQI.PaginationProps> = ({
  currentPage = 0,
  totalPages = 0,
  onIndexChange = () => {},
  pagesToShow = 7,
}) => {
  // Numbers to the left and right to show around active number
  const padding = (pagesToShow - 1) / 2;
  const pageNumbers = [] as number[];

  if (totalPages < pagesToShow) {
    // If there are less than 7 pages, only return page numbers for the available amount
    for (let i = 0; i < totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    if (currentPage <= (pagesToShow - padding - 1)) {
      // If the current page is <= the number that is initially in the center of 
      // the number buttons, return the first set of numbers (I.E. if we're showing 5 buttons
      // and the current page is 1, 2, or 3, return 1-5.
      for (let i = 0; i < pagesToShow; i++) {
        pageNumbers.push(i);
      }
    } else if (currentPage >= (totalPages - padding - 1)) {
      // If the current page is >= the last possible centered number, return the final set (I.E.
      // if there are 10 pages total and the current page is 8, 9, or 10, return 6-10.
      for (let i = (totalPages - pagesToShow); i < totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      for (let i = (currentPage - padding); i < (currentPage + padding + 1); i++) {
        pageNumbers.push(i);
      }
    }
  }

  const scrollToTopOnIndexChange = (index: number) => {
    window.scrollTo(0, 0);
    onIndexChange(index);
  };
  
  return (
    <>
    {pageNumbers.length > 1 &&
      <nav className="pagination">
        <ul className="pagination__list">
          {
            currentPage > 0 &&
            <>
              <li className="pagination__list-item pagination__list-item--first">
                <button className="pagination__button" type="button" onClick={() => { scrollToTopOnIndexChange(0) }}>
                  <span className="sr-only">First Results Page</span>
                  <ChevronDoubleIcon className="icon icon-pagination-chevron-double" aria-hidden="true" width="19" height="16" />
                </button>
              </li>
              <li className="pagination__list-item pagination__list-item--prev">
                <button className="pagination__button" type="button" onClick={() => { scrollToTopOnIndexChange(currentPage - 1) }}>
                  <span className="sr-only">Previous Results Page</span>
                  <ChevronSingleIcon className="icon icon-pagination-chevron-single" aria-hidden="true" width="10" height="16" />
                  <span className="pagination__button-text" aria-hidden="true">Prev</span>
                </button>
              </li>
            </>
          }
          
          {
            pageNumbers.map((pageNumber, i) => {
              const active = pageNumber === currentPage;
              const classList = classNames({
                "pagination__list-item": true,
                "prev": pageNumber === currentPage - 1,
                "next": pageNumber === currentPage + 1,
                active,
              });
              return (
                <li className={classList} key={`pagination-list-item-${i}`}>
                  <PaginationButton
                    pageNumber={pageNumber}
                    active={active}
                    onClick={(newIndex: number) => { scrollToTopOnIndexChange(newIndex) }}
                  />
                </li>
              );
            })
          }
          {
            currentPage < totalPages - 1 &&
            <>
              <li className="pagination__list-item pagination__list-item--next">
                <button className="pagination__button" type="button" onClick={() => { scrollToTopOnIndexChange(currentPage + 1) }}>
                  <span className="sr-only">Next Results Page</span>
                  <ChevronSingleIcon className="icon icon-pagination-chevron-single" aria-hidden="true" width="10" height="16" />
                  <span className="pagination__button-text" aria-hidden="true">Next</span>
                </button>
              </li>
              <li className="pagination__list-item pagination__list-item--last">
                <button className="pagination__button" type="button" onClick={() => { scrollToTopOnIndexChange(totalPages - 1) }}>
                  <span className="sr-only">Last Results Page</span>
                  <ChevronDoubleIcon className="icon icon-pagination-chevron-double" aria-hidden="true" width="19" height="16" />
                </button>
              </li>
            </>
          }
        </ul>
      </nav>
    }
    </>
  );
};

export default Pagination;