import React from "react";
import ArticleFeedItem from "../components/ArticleFeedItem";

const ArticlesSearchResult: React.FC<JQI.ArticlesSearchResultProps> = ({
  _snippetResult,
  ...rest
}) => {
  const feedItemProps = {
    ...rest,
    body: {
      summary: _snippetResult?.body?.summary?.value?.replace(" …", "…") || "",
      processed: rest.body?.processed,
    },
  } as JQI.ArticleFeedItemProps;
  return (
    <ArticleFeedItem {...feedItemProps} />
  )
};

export default ArticlesSearchResult;