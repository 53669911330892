import React from "react";
import { Link } from "gatsby";
import classNames from "classnames";
import useExternalLink from "../../hooks/use-external-link";

const BasicLink: React.FC<JQI.BasicLinkProps> = ({
  children,
  url,
}) => {
  const isExternal = useExternalLink(url);

  const classList = classNames({
    "link": true,
    "link--external": isExternal,    
  });

  if (url.indexOf("/") === 0) {
    return (
      <Link className={classList} to={url}>{children}</Link>
    );
  } else {
    return (
      // Note: This component can link internally or externally. When this component
      // is wrapped at a higher order with Gatsby navigation, it can cause the back
      // button in the browser to behave strangely. So we stop event propagation here
      // to avoid both the <a> nav and the higher level nav firing.
      <a className={classList} href={url} onClick={(e) => {
        if(e) {
          e.stopPropagation()
        } else if(window.event) {
          window.event.cancelBubble = true
        }
      }}>{children}</a>
    );
  }
}

export default BasicLink;