const query = `{
  allNodeCourse(
    filter: { fields: { pagePath: {ne: null}}}
    sort: [{created: DESC}, {title: ASC}]
  ) {
    edges {
      node {
        id
        courseId: field_course_id
        title
        date: created(formatString: "MMMM D, YYYY")
        fields {
          pagePath
        }
        sites: field_sites {
          id: drupal_internal__target_id
        }
        body {
          summary
          processed
        }
        internal {
          contentDigest
        }
        relationships {
          hero: field_hero {
            relationships {
              heroImage: field_hero_image {
                mediaImage: field_media_image {
                  alt
                }
                relationships {
                  mediaImage: field_media_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          aspectRatio: 1.5
                          layout: FIXED
                          transformOptions: {cropFocus: CENTER}
                        )
                      }
                    }
                  }
                }
              }
            }
          }
          people: field_people {
            id
            name: title
            lastName: field_person_last_name
            personTitle: field_person_title
            field_sites {
              drupal_internal__target_id
            }
            fields {
              pagePath
            }
            relationships {
              image: field_person_image {
                mediaImage: field_media_image {
                  alt
                }
                relationships {
                  mediaImage: field_media_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          aspectRatio: 1
                          transformOptions: {cropFocus: CENTER}
                          width: 275
                        )
                      }
                    }
                  }
                }
              }
              roles: field_person_roles {
                drupal_internal__tid
                name
              }
            }
          }
          semester: field_semester {
            id
            name
            field_semester_calendar_date (formatString: "YYYYMMDD")
          }
          field_syllabus {
            relationships {
              mediaDocument: field_media_document {
                uri {
                  url
                }
              }
            }
          }
        }
        filters: relationships {
          sites: field_sites {
            id: drupal_internal__tid
          }
          semester: field_semester {
            id: drupal_internal__tid
            field_semester_calendar_date (formatString: "YYYYMMDD")
          }
        }
      }
    }
  }
}`;

const transformer = (result) => {
  return result.data.allNodeCourse.edges
    .map(({
      node: {
        id,
        date,
        relationships,
        filters,
        ...rest
      },
    }) => ({
      objectID: id,
      timestamp: (new Date(date)).getTime(),
      filters: {
        sites: filters.sites.map(({ id }) => id),
        semester: filters.semester.map(({ id }) => id),
      },
      date,
      semesterEpochDate: relationships.semester.map(semester => {
        const dateString = semester.field_semester_calendar_date;
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6) - 1; // Subtract 1 because months are 0-indexed in JavaScript Dates
        const day = dateString.substring(6);
        const date = new Date(year, month, day);
        return date.getTime();
      })[0],
      semesterDate: relationships.semester.map(semester => semester.field_semester_calendar_date)[0],
      ...rest
    }));
};

module.exports = function generateConfig(groupId) {
  return {
    indexName: `courses_index_${groupId}`,
    settings: {
      searchableAttributes: [
        "title",
        "body.summary",
      ],
      attributesForFaceting: [
        "filterOnly(filters.sites)",
        "filterOnly(filters.semester)",
      ],
      attributesToSnippet: [
        "body.summary:60",
      ],
      hitsPerPage: 30,
      snippetEllipsisText: "…",
    },
    mergeSettings: true,
    query,
    transformer,
  }
}
