import React from "react";
import { Helmet, HelmetProps } from "react-helmet";
import useSiteMetadata from "../../hooks/use-site-metadata";
import OpenGraphMeta from "../OpenGraphMeta";
import favicon from "../../images/favicon.ico";

const generatePageUrl = (base: string, path: string = "/"): string => {
  try {
    const url = new URL(path, base);
    return url.toString();
  } catch(ex) {
    return base;
  }
};

const HTMLHead: React.FC<JQI.HTMLHeadProps> = ({
  title,
  path = "/",
  description,
  openGraph = {},
}) => {
  const siteMetadata = useSiteMetadata();
  description = !!description ? description : siteMetadata.description;

  const props = {
    htmlAttributes: {
      lang: "en",
    },
    title: `${title} | ${siteMetadata.title}`,
    meta: [
      {
        name: "description",
        content: description,
      },
    ],
  } as HelmetProps;
  const ogMetaProps = {
    url: generatePageUrl(siteMetadata.siteUrl, path),
    title: `${title} | ${siteMetadata.title}`,
    description,
    ...openGraph,
  } as JQI.OpenGraphMetaProps;
  
  return (
    <>
      <Helmet {...props}>
        <link rel="icon" href={favicon} type="image/vnd.microsoft.icon"></link>
      </Helmet>
      <OpenGraphMeta {...ogMetaProps} />
    </>
  );
};

export default HTMLHead;