const React = require("react");
const { useStaticQuery, graphql } = require("gatsby");

module.exports = function useQuicsEventTypes() {
  const { allTaxonomyTermQuicsEventTypes, allNodeEvent } = useStaticQuery(
    graphql`query AllQuicsEventTypes {
      allNodeEvent(
        filter: {fields: {pagePath: {ne: null}}}
      ) {
        distinct(field: {relationships: {field_quics_event_types: {name: SELECT}}})
      }
      allTaxonomyTermQuicsEventTypes {
        edges {
          node {
            id: drupal_internal__tid
            name
          }
        }
      }
    }`
  );

  // A list of the event types used on the QuICS site
  const localEventTypes = allNodeEvent.distinct;
  
  // Remove events types that are not in localEventTypes
  const filteredEventTypes = allTaxonomyTermQuicsEventTypes.edges.filter((item) => {
    return localEventTypes.indexOf(item.node.name) !== -1
  });

  return filteredEventTypes.map((edge) => ({
    ...edge.node,
    id: `${edge.node.id}`,
  }));
};