import React from "react";
import { navigate } from "gatsby";
import { GatsbyImage, getImage, ImageDataLike, GatsbyImageProps, StaticImage } from "gatsby-plugin-image";
import classNames from "classnames";
import FancyLink from "../FancyLink";
import useHover from "../../hooks/use-hover";
import "./PersonCard.scss";

const PersonCard: React.FC<JQI.PersonFeedItemProps> = ({
  name,
  personTitle,
  fields,
  relationships,
}) => {
  const [ hoverRef, hovered ] = useHover();
  const classList = classNames({
    "person-card": true,
    hovered,
  });
  const imageData = relationships?.image?.relationships?.mediaImage?.localFile ?? null as unknown as ImageDataLike;
  const imageProps = {
    image: !!imageData ? getImage(imageData) : null,
    alt: !!imageData ? relationships.image?.mediaImage.alt : "",
  } as GatsbyImageProps;

  return (
    <article className={classList} onClick={() => { navigate(fields.pagePath) }} ref={hoverRef}>
      {
        !!imageProps.image &&
        <GatsbyImage className="person-card__image" {...imageProps} />
      }
      {
        !imageProps.image &&
        <StaticImage className="person-card__image" src="../../images/person-placeholder.svg" alt="Peron image placeholder" width={210} height={210} />
      }
      <div className="person-card__content">
        <h2 className="person-card__name">
          <FancyLink href={fields.pagePath}>{name}</FancyLink>
        </h2>
        {
          !!personTitle &&
          <p className="person-feed-item__title">{personTitle}</p>
        }
      </div>
    </article>
  );
};

export default PersonCard;
