import React from "react";
import "./PageContentMain.scss";

const PageContentMain: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  children,
}) => (
  <div className="page-content__main">
    {children}
  </div>
);

export default PageContentMain;