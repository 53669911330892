import React from "react";
import { PageProps, graphql } from "gatsby";
import _ from "lodash";
import useSiteMetadata from "../hooks/use-site-metadata";
import useLinksetNav from "../hooks/use-linkset-nav";
import { useNavHierarchies } from "gatsby-jqi-graphql-helpers";
import { getImage, ImageDataLike } from "gatsby-plugin-image";
import HeroParagraph from "../wrappers/HeroParagraph";
import HTMLHead from "../components/HTMLHead";
import PageContentBody from "../components/PageContentBody";
import PageContentHeader from "../components/PageContentHeader"
import PageContentLayout from "../layouts/PageContentLayout";
import PageContentMain from "../components/PageContentMain";
import PageContentSidebar from "../components/PageContentSidebar";
import ParagraphWidget from "../wrappers/ParagraphWidget";
import SectionNav from "../components/SectionNav";
import SidebarNav from "../components/SidebarNav";
import TextHTMLParagraph from "../wrappers/TextHTMLParagraph";

const BasicPage: React.FC<PageProps<JQI.BasicPageData, JQI.BasicPageContext>> = ({
  location,
  data,
  pageContext,
}) => {
  const siteMetadata = useSiteMetadata();
  const { page } = data;
  const { defaultNodesByPath ={} } = pageContext;
  const { breadcrumb, sidebar } = useNavHierarchies(page.fields.pagePath, defaultNodesByPath)

  const linksetSidebar = (page.linkset && page.linkset[0]) ? useLinksetNav(page.fields.pagePath, page.linkset[0]) : null;
  const sortedSidebar = _.orderBy(sidebar.current, "title", "asc");
  const { hero, widgets } = page.relationships;
  const finalSidebar = linksetSidebar ? linksetSidebar : sidebar;
  const finalSortedSidebar = linksetSidebar ? linksetSidebar.current : sortedSidebar;
  const sectionNavItems = finalSidebar.current?.filter(({ url }: {url: string}) => url === location.pathname)?.[0]?.children;
  
  // Extract imageUrl for use in og:image
  const metaImageData = hero?.relationships.heroImage?.relationships.mediaImage.localFile as ImageDataLike;
  const image = !!metaImageData ? getImage(metaImageData): null;
  const imageUrl = siteMetadata.siteUrl + image?.images.fallback?.src;

  // Extract description for use in meta
  const meta = page.metatag?.filter((tag) => tag.attributes.name === "description").map((tag) => (
    {
      description: tag.attributes.content,
    }
  )).pop();

  return (
    <>
      <HTMLHead
        title={page.title}
        description={page.body?.summary || meta?.description}
        path={page.fields.pagePath}
        openGraph={{
          article: {
            publishedTime: page.created,
            modifiedTime: page.changed,
          },
          image: {
            url: imageUrl || "",
          },
        }}
      />
      <PageContentLayout>
        <PageContentSidebar>
          <SidebarNav {...finalSidebar} menuLinks={finalSortedSidebar}  />
        </PageContentSidebar>
        <PageContentMain>
          <PageContentHeader breadcrumb={breadcrumb} title={page.title} />
          <PageContentBody>
            <SectionNav items={sectionNavItems} />
            {
              !!hero &&
              <HeroParagraph {...hero} />
            }
            {
              !!page.body?.processed &&
              <TextHTMLParagraph className="page-body" text={page.body} />
            }
            {
              !!widgets.length &&
              widgets.map((widget, i) => (
                <ParagraphWidget
                  key={i}
                  label={widget.relationships?.paragraphType.label || ""}
                  widget={widget}
                  groupId={siteMetadata.groupId}
                />
              ))
            }
          </PageContentBody>
        </PageContentMain>
      </PageContentLayout>
    </>
  );
};

export default BasicPage;

export const query = graphql`
  query BasicPageQuery($id: String!) {
    page: nodePage(id: { eq: $id }) {
      ...BasicPage
      meta: field_meta {
        description
      }
    }
  }
`;