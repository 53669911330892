import React from "react";
import ButtonLink from "../components/ButtonLink";
import ButtonSet from "../components/ButtonSet";

const ButtonSetParagraph: React.FC<JQI.ButtonSetParagraphProps> = ({
  relationships,
}) => {
  return (
    <ButtonSet>
      {
        relationships.buttons.map(({ style, link }, i) => (
          <ButtonLink key={`button-set-button-${i}`} variant={style} href={link.uri}>{link.title}</ButtonLink>
        ))
      }
    </ButtonSet>
  );
};

export default ButtonSetParagraph;