import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { globalHistory } from "@reach/router";
import classNames from "classnames";
import SearchIcon from "../../icons/search.svg";
import HamburgerIcon from "./HamburgerIcon";
import MainNav from "./MainNav";
import "./SiteHeader.scss";

const defaultLogo = {
  src: "/jqi-logo-dark.svg",
  alt: "UMD Joint Quantum Institute logo",
  width: 343,
  height: 69,
};

const SiteHeader: React.FC<JQI.SiteHeaderProps> = ({
  logo = defaultLogo,
  displaySearch = true,
  menuItems,
}) => {
  const [ isHidden, setIsHidden ] = useState<boolean>(true);
  const [ isTransitioning, setIsTransitioning ] = useState<boolean>(false);
  useEffect(() => {
    globalHistory.listen(({ action }) => {
      if (action === "PUSH" || action === "POP") {
        setIsTransitioning(false);
        setIsHidden(true);
      }
    });
  });
  const handleToggleClick = () => {
    setIsTransitioning(true);
    setIsHidden(!isHidden);
  };
  const handleTransitionEnd = (event: React.TransitionEvent<HTMLElement>) => {
    if (isTransitioning) {
      const target = event.target as HTMLElement;
      if (target.classList.contains("site-header__nav")) {
        setIsTransitioning(false);
      }
    }
  };
  const classList = classNames({
    "site-header": true,
    [`site-header--transitioning`]: isTransitioning,
  });
  return (
    <header className={classList} onTransitionEndCapture={handleTransitionEnd}>
      <div className="site-header__inner">
        <div className="site-header__logo">
          <Link to="/" className="responsive-img" style={{paddingTop: `${(logo.height / logo.width * 100)}%`}}>
            <img src={logo.src} alt={logo.alt} loading="lazy" />
          </Link>
        </div>
        <div className="site-header__mobile-controls">
          {
            displaySearch &&
            <Link to="/search" className="site-header__search">
              <span className="sr-only">Search</span>
              <SearchIcon className="icon icon-search" aria-hidden="true" height="22" width="22" />
            </Link>
          }
          <button className="site-header__nav-toggle" aria-expanded={!isHidden} onClick={handleToggleClick}>
            <span className="sr-only">{`${isHidden ? "Show" : "Hide"} Main Menu` }</span>
            <HamburgerIcon expanded={!isHidden} />
          </button>
        </div>
        <MainNav hidden={isHidden} items={menuItems} />
        {
          displaySearch &&
          <Link to="/search" className="site-header__search hidden 1000:block">
            <span className="sr-only">Search</span>
            <SearchIcon className="icon icon-search" aria-hidden="true" height="22" width="22" />
          </Link>
        }
      </div>
    </header>
  );
};

export default SiteHeader;