import React from "react";
import SkipNavLink from "../../components/SkipNavLink";
import SiteHeader from "../../components/SiteHeader";
import SiteFooter from "../../components/SiteFooter";
import "./BaseLayout.scss";

const BaseLayout: React.FC<JQI.BaseLayoutProps> = ({
  children,
  headerProps,
  footerProps,
}) => {
  return (
    <div className="base-layout">
      <SkipNavLink />
      <SiteHeader {...headerProps} />
      <main id="main-content">
        {children}
      </main>
      <SiteFooter {...footerProps} />
    </div>
  );
};

export default BaseLayout;