import React from "react";

const PaginationButton: React.FC<JQI.PaginationButtonProps> = ({
  active,
  pageNumber,
  onClick,
}) => {
  return (
    <button className="pagination__button" type="button" disabled={active} onClick={() => { onClick(pageNumber) }}>
      <span className="sr-only">Go to Results Page&nbsp;</span>
      <span className="pagination__button-number">{ pageNumber + 1 }</span>
    </button>
  )
};

export default PaginationButton;